export enum CoachesBillingSegments {
  CoachId = ':coachId'
}

export enum CoachesBillingResource {
  BillingDashboard = 'billing/dashboard',
  Invoices = `${CoachesBillingSegments.CoachId}/billing/invoices`,
  InvoiceItems = `${CoachesBillingSegments.CoachId}/billing/invoices/items`
}

export enum CoachesBillingDashboardResource {
  Users = 'users',
  Totals = 'totals'
}
