import { useContext } from 'react'
import { useReactiveVar } from '@apollo/client'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import SchoolIcon from '@mui/icons-material/School'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import { useFlagsStatus } from '@unleash/proxy-client-react'
import {
  CoachesBillingDashboardResource,
  CoachesBillingResource
} from 'coachesBillingResource.types'
import { AuthorizedFeature, Loader } from 'components'
import {
  resetCondensedEventCardsVar,
  resetEventFilters,
  dateRangeDropDownQueryParamsVar
} from 'context'
import { DppChatContext } from 'context/dpp/ChatProvider'
import { DppResource } from 'dppResource.types'
import { useAuthorizedFeature } from 'hooks'
import { currentUserId } from 'hooks/useInvalidateQueriesOnUserChange'
import { NutrisenseShortLogo } from 'icons/NutrisenseShortLogo'
import { LocationPaths } from 'location.types'
import { startCase } from 'lodash'
import { AdminPanelSidebarIcon } from 'screens/AdminPanel/AdminPanelSidebarIcon'
import { ChatbotAssistantSidebarItem } from 'screens/Chatbot/Assistant'
import { QuestionnaireSidebarIcon } from 'screens/Questionnaire/QuestionnaireSidebarIcon'
import { UserResource } from 'userResource.types'
import { HealthPortalFeature, useCurrentAdminQuery, useCurrentUserQuery } from 'types'
import { SidebarItem } from './SidebarItem'
import { SidebarNavStyled, SidebarTop, SidebarBottom } from './styles'

interface ISidebarNavigationProps {
  isAssistantOpen: boolean
  onToggleAssistant: () => void
}

export function SidebarNavigation({ isAssistantOpen, onToggleAssistant }: ISidebarNavigationProps) {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()

  const id = currentUserId || currentUser?.id

  const [coachesBillingDashboardAuthorized, coachesBillingDetailsAuthorized] = useAuthorizedFeature(
    [HealthPortalFeature.CoachesBillingDashboard, HealthPortalFeature.CoachesBillingDetails]
  )

  const { flagsReady } = useFlagsStatus()

  let dateRangeDropDownQueryParams = useReactiveVar(dateRangeDropDownQueryParamsVar)
  dateRangeDropDownQueryParams = dateRangeDropDownQueryParams
    ? `?${dateRangeDropDownQueryParams}`
    : ''

  const userPath = `${LocationPaths.Users}/${id}`

  const { unreadMessagesCount: unreadDppMessagesCount } = useContext(DppChatContext)

  const coachInvoiceDetailsPath =
    currentAdmin && `${LocationPaths.Coaches}/${currentAdmin.id}/billing/invoices/items`

  const navLinks = (
    <>
      <SidebarTop>
        <SidebarItem
          end
          to={`${userPath}${dateRangeDropDownQueryParams}`}
          tooltipTitle="Home"
          onClick={() => {
            resetEventFilters()
            resetCondensedEventCardsVar()
          }}
        >
          <HomeOutlinedIcon />
        </SidebarItem>

        <SidebarItem
          to={`${userPath}/${UserResource.History}${dateRangeDropDownQueryParams}`}
          tooltipTitle={startCase(UserResource.History)}
          onClick={resetEventFilters}
        >
          <AccessTimeIcon />
        </SidebarItem>

        <AuthorizedFeature feature={HealthPortalFeature.Chat}>
          <SidebarItem
            to={`${userPath}/${UserResource.Chat}`}
            tooltipTitle={startCase(UserResource.Chat)}
          >
            <MessageOutlinedIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.BillingDashboard}>
          <SidebarItem
            to={`${userPath}/${UserResource.BillingDashboard}`}
            tooltipTitle="Billing Dashboard"
          >
            <CreditCardOutlinedIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <SidebarItem
          to={`${userPath}/${UserResource.Insights}${dateRangeDropDownQueryParams}`}
          tooltipTitle={startCase(UserResource.Insights)}
        >
          <TrendingUpOutlinedIcon />
        </SidebarItem>

        <AuthorizedFeature feature={HealthPortalFeature.SurveysViewHealthQuestionnaire}>
          <SidebarItem
            to={`${userPath}/${UserResource.Questionnaire}`}
            tooltipTitle={startCase(UserResource.Questionnaire)}
          >
            <QuestionnaireSidebarIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.Files}>
          <SidebarItem
            to={`${userPath}/${UserResource.Files}`}
            tooltipTitle={startCase(UserResource.Files)}
          >
            <FolderOpenOutlinedIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <SidebarItem
          to={`${userPath}/${UserResource.Chatbot}`}
          tooltipTitle={startCase(UserResource.Chatbot)}
        >
          <AutoAwesomeIcon />
        </SidebarItem>

        <AuthorizedFeature feature={HealthPortalFeature.ChatbotViewKnowledgeBase}>
          <SidebarItem to={LocationPaths.Knowledge} tooltipTitle="Knowledge Base">
            <MenuBookIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.Financials}>
          <SidebarItem
            to={LocationPaths.Financials}
            tooltipTitle={startCase(LocationPaths.Financials)}
          >
            <AttachMoneyIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.SensorsReplacementRequests}>
          <SidebarItem
            to={LocationPaths.ReplacementRequests}
            tooltipTitle={startCase(LocationPaths.ReplacementRequests)}
          >
            <ReplayOutlinedIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.ShipmentsViewUser}>
          <SidebarItem
            to={`${userPath}/${UserResource.Shipments}`}
            tooltipTitle={startCase(UserResource.Shipments)}
          >
            <LocalShippingIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.AdminPanelMembers}>
          <SidebarItem to={LocationPaths.AdminPanel} tooltipTitle="Admin Panel">
            <AdminPanelSidebarIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.DppViewCohorts}>
          <SidebarItem
            to={
              unreadDppMessagesCount > 0
                ? LocationPaths.Dpp + '/' + DppResource.DppChat
                : LocationPaths.Dpp
            }
            tooltipTitle="Diabetes Prevention Program"
            showIndicator={unreadDppMessagesCount > 0}
          >
            <SchoolIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.SurveysViewHealthQuestionnaireApprovals}>
          <SidebarItem to={LocationPaths.Approvals} tooltipTitle="Approvals">
            <CheckCircleOutlineOutlinedIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.SurveysManagement}>
          <SidebarItem to={LocationPaths.Surveys} tooltipTitle="Surveys">
            <FactCheckOutlinedIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.SupportTickets}>
          <SidebarItem to={LocationPaths.SupportTickets} tooltipTitle="Support Tickets">
            <InboxOutlinedIcon />
          </SidebarItem>
        </AuthorizedFeature>

        <AuthorizedFeature feature={HealthPortalFeature.SupportAccountRequests}>
          <SidebarItem
            to={LocationPaths.AccountRequests}
            tooltipTitle="Account Processes Dashboard"
          >
            <ReceiptOutlinedIcon />
          </SidebarItem>
        </AuthorizedFeature>
        <AuthorizedFeature feature={HealthPortalFeature.EditHealthTeamStructure}>
          <SidebarItem
            to={LocationPaths.NutritionistsOrganization}
            tooltipTitle="Nutritionists Organization"
          >
            <AdminPanelSettingsOutlinedIcon />
          </SidebarItem>
        </AuthorizedFeature>

        {coachesBillingDashboardAuthorized && (
          <SidebarItem
            to={`${LocationPaths.Coaches}/${CoachesBillingResource.BillingDashboard}/${CoachesBillingDashboardResource.Users}`}
            tooltipTitle="Coach Billing Dashboard"
          >
            <AccountBalanceOutlinedIcon />
          </SidebarItem>
        )}
        {!coachesBillingDashboardAuthorized &&
          coachesBillingDetailsAuthorized &&
          coachInvoiceDetailsPath && (
            <SidebarItem to={coachInvoiceDetailsPath} tooltipTitle="Coach Invoice Details">
              <AccountBalanceOutlinedIcon />
            </SidebarItem>
          )}
      </SidebarTop>
      <SidebarBottom>
        <ChatbotAssistantSidebarItem active={isAssistantOpen} onClick={onToggleAssistant} />
      </SidebarBottom>
    </>
  )

  return (
    <SidebarNavStyled>
      <NutrisenseShortLogo sx={{ width: 36, height: 36, alignSelf: 'center' }} />
      {flagsReady ? navLinks : <Loader />}
    </SidebarNavStyled>
  )
}
