import { useRef } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useDebouncedSearch } from 'hooks/useDebouncedSearch'
import {
  useAdminPanelAllCoachesLazyQuery,
  UserRole,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  UserDynamicFilterField,
  SortDirection,
  UserSortField,
  IUser
} from 'types'

export interface ICoachFilterCoach {
  id: string
  fullName: string
  email: string
}

interface ICoachFilterProps {
  onUserSelect: (user: ICoachFilterCoach) => void
  placeholderText?: string
}

export const CoachFilter = ({ onUserSelect, placeholderText }: ICoachFilterProps) => {
  const searchValue = useRef('')

  const [searchUsers, { data, loading }] = useAdminPanelAllCoachesLazyQuery({
    variables: {
      dynamicFilters: [
        {
          items: [
            {
              field: UserDynamicFilterField.FullName,
              operation: DynamicFilterItemOperation.ICont,
              value: searchValue.current
            },
            {
              field: UserDynamicFilterField.Role,
              operation: DynamicFilterItemOperation.In,
              value: [UserRole.Nutritionist, UserRole.NutritionistManager].join(',')
            }
          ],
          operator: DynamicFilterOperator.And
        }
      ],
      sorts: [
        {
          direction: SortDirection.Asc,
          field: UserSortField.FullName
        }
      ]
    }
  })

  const { handleInputChange } = useDebouncedSearch(searchUsers, searchValue)
  const options = data?.allUsers.users ?? []

  const handleChange = (_: any, coach: (typeof options)[number]) => {
    onUserSelect({ id: coach.id, fullName: coach.fullName, email: coach.email })
  }

  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={(user: IUser) => `${user.fullName} (${user.email})`}
      isOptionEqualToValue={(userA, userB) => userA?.id === userB?.id}
      loading={loading}
      noOptionsText="No Nutritionist was found"
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={placeholderText}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}
