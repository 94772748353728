import { useAuth } from 'context'
import {
  AccountRequestCancelSubscriptionRequestPayloadCancellationType,
  HealthPortalFeature,
  OpsAccountRequestKind
} from 'types'

export const useCanApproveOrRejectAccountRequest = () => {
  const { isAuthorized } = useAuth()

  return ({
    accountRequestKind,
    accountRequestSubtype = undefined
  }: {
    accountRequestKind: OpsAccountRequestKind
    accountRequestSubtype?: AccountRequestCancelSubscriptionRequestPayloadCancellationType
  }) => {
    if (accountRequestKind === OpsAccountRequestKind.CancelSubscription && accountRequestSubtype) {
      switch (accountRequestSubtype) {
        case AccountRequestCancelSubscriptionRequestPayloadCancellationType.AtCommitmentEnd:
          return isAuthorized(
            HealthPortalFeature.SupportAccountRequestsProcessCancelSubscriptionAtCommitmentEnd
          )
        case AccountRequestCancelSubscriptionRequestPayloadCancellationType.Immediately:
          return isAuthorized(
            HealthPortalFeature.SupportAccountRequestsProcessCancelSubscriptionImmediately
          )
        case AccountRequestCancelSubscriptionRequestPayloadCancellationType.AtPeriodEnd:
          return isAuthorized(
            HealthPortalFeature.SupportAccountRequestsProcessCancelSubscriptionAtPeriodEnd
          )
        default:
          return false
      }
    }

    switch (accountRequestKind) {
      case OpsAccountRequestKind.PauseSubscription:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessPauseSubscription)
      case OpsAccountRequestKind.UnpauseSubscription:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessUnpauseSubscription)
      case OpsAccountRequestKind.UncancelSubscription:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessUncancelSubscription)
      case OpsAccountRequestKind.UpdateSubscription:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessUpdateSubscription)
      case OpsAccountRequestKind.Credit:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessCredit)
      case OpsAccountRequestKind.OneOffAddon:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessOneOffAddon)
      case OpsAccountRequestKind.ChangeSubscriptionBillingCycle:
        return isAuthorized(
          HealthPortalFeature.SupportAccountRequestsProcessChangeSubscriptionBillingCycle
        )
      case OpsAccountRequestKind.MiscRequest:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessMiscRequest)
      case OpsAccountRequestKind.DeleteDuplicateAccount:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessDeleteDuplicateAccount)
      case OpsAccountRequestKind.Refund:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessRefund)
      case OpsAccountRequestKind.GrantFreeVideoCall:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsProcessGrantFreeVideoCall)
      default:
        return false
    }
  }
}
