import { Button } from '@mui/material'
import { IBulkUpdateProviderProps } from 'components/DataGrid/Toolbar'
import { ICoachAssignment } from 'types'

export const BulkUpdateProvider = (props: IBulkUpdateProviderProps) => {
  const { selectedRows, bulkUpdate, resetSelectedRows } = props

  const coachAssignments: ICoachAssignment[] = Array.from(selectedRows.values())

  const handleOnClick = () => {
    const ids = coachAssignments.map((coachAssignment) => coachAssignment.id)
    bulkUpdate(ids, {}, resetSelectedRows)
  }

  return (
    <Button
      variant="contained"
      size="medium"
      sx={styles.enrollButton}
      onClick={handleOnClick}
      disabled={selectedRows.size === 0}
    >
      Send Engagement Message
    </Button>
  )
}

const styles = {
  enrollButton: {
    position: 'absolute',
    bottom: '-45px',
    right: 0
  }
}
