import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { ServerError } from '@apollo/client/link/utils'
import { googleLogout } from '@react-oauth/google'
import { client } from 'config/apolloClient'
import { LocationPaths } from 'location.types'
import { useNavigate } from 'react-router-dom'
import {
  ISignInUserMutation,
  ThirdPartySignInProvider,
  useCurrentUserQuery,
  useSignInThirdPartyUserMutation,
  useSignInUserMutation,
  useSignOutUserMutation,
  HealthPortalFeature,
  UserRole,
  useAuthorizedFeaturesQuery
} from 'types'
import { snackBarVisibleVar } from './ui'

export interface ILoginData {
  email: string
  password: string
}

const WAITING_FOR_PROVIDER = 'Waiting for provider'
const INVALID_CREDENTIALS = 'Invalid credentials'
const USE_GOOGLE_LOGIN = 'Please use Google login'
const NOT_AUTHORIZED =
  'Before you use the Health Portal you need to ask your manager for permission'
const LOGIN_FAILED = 'Unknown error. Talk with the engineering team'
const NETWORK_ISSUE = 'Network issue, try again'

const defaultValues = {
  isAuthorized: (_feature: HealthPortalFeature) => false,
  loading: false,
  basicAuthLogin: async (_: ILoginData): Promise<ISignInUserMutation | void> => {
    throw WAITING_FOR_PROVIDER
  },
  googleLogin: async (_: string): Promise<void> => {
    throw WAITING_FOR_PROVIDER
  },
  logout: async (): Promise<void> => {}
}

export const AuthContext = createContext(defaultValues)
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const { data: { currentUser } = {}, loading } = useCurrentUserQuery()
  const [loggedIn, setLoggedIn] = useState(false)
  const navigate = useNavigate()

  const [loginFn] = useSignInUserMutation({
    onError: (e) => {
      if (e.networkError && (e.networkError as ServerError).statusCode === 401) {
        throw INVALID_CREDENTIALS
      }
      throw NETWORK_ISSUE
    }
  })

  const [googleLoginFn] = useSignInThirdPartyUserMutation({
    onError: (e) => {
      if (e.networkError && (e.networkError as ServerError).statusCode === 401) {
        throw INVALID_CREDENTIALS
      }
      throw NETWORK_ISSUE
    }
  })

  const {
    data: authorizedFeaturesData,
    loading: authorizing,
    refetch: getAuthorizedFeatures
  } = useAuthorizedFeaturesQuery()

  useEffect(() => {
    setLoggedIn(!!currentUser)
  }, [currentUser])

  const [logoutFn] = useSignOutUserMutation({
    onError: () => {
      snackBarVisibleVar({
        open: true,
        message: 'Unable to sign out. Please try again later.'
      })
    },
    onCompleted: () => {
      client.clearStore()
      localStorage.clear()
      setLoggedIn(false)
      googleLogout()
      navigate(LocationPaths.Login)
    }
  })

  const basicAuthLogin = async (loginData: ILoginData) => {
    const { data: response } = await loginFn({
      variables: { email: loginData.email, password: loginData.password }
    })
    handleResponse(response?.signInUser?.token, response?.signInUser?.role)

    if (!response) {
      return
    }

    return response
  }

  const googleLogin = async (idToken: string): Promise<void> => {
    const { data: response } = await googleLoginFn({
      variables: { provider: ThirdPartySignInProvider.Google, providerToken: idToken }
    })
    await handleResponse(
      response?.signInThirdPartyUser?.token,
      response?.signInThirdPartyUser?.role
    )
  }

  const handleResponse = async (token?: string | null, role?: string | null) => {
    if (token !== null && role && role !== UserRole.User) {
      await getAuthorizedFeatures()
      setLoggedIn(true)
    } else if (token === null) {
      if (role === UserRole.Admin) {
        throw USE_GOOGLE_LOGIN
      } else {
        throw INVALID_CREDENTIALS
      }
    } else if (!role || role === UserRole.User) {
      throw NOT_AUTHORIZED
    } else {
      throw LOGIN_FAILED
    }
  }

  const isAuthorized = useCallback(
    (feature: HealthPortalFeature) => {
      if (!loggedIn && !currentUser) {
        return false
      }

      const authorizedFeatures = authorizedFeaturesData?.authorizedFeatures.features ?? []
      return authorizedFeatures.includes(feature)
    },
    [authorizedFeaturesData, loggedIn, currentUser]
  )

  const value = {
    isAuthorized,
    loading: loading || authorizing,
    basicAuthLogin,
    googleLogin,
    logout: async () => {
      await logoutFn()
    }
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
