import { IUser } from 'types'

export const buildNutritionistOption = (nutritionist?: IUser) => {
  if (!nutritionist) {
    return null
  }

  return {
    label: nutritionist.email,
    value: nutritionist.id
  }
}
