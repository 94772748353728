import { cloneElement, ReactElement } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { CreditModal } from 'components/AccountRequestsMenu/modals/CreditModal'
import { DeleteDuplicateAccountModal } from 'components/AccountRequestsMenu/modals/DeleteDuplicateAccountModal'
import { MiscRequestModal } from 'components/AccountRequestsMenu/modals/MiscRequestModal'
import { OneOffAddonModal } from 'components/AccountRequestsMenu/modals/OneOffAddonModal'
import { RefundModal } from 'components/AccountRequestsMenu/modals/RefundModal/RefundModal'
import { UncancelSubscriptionModal } from 'components/AccountRequestsMenu/modals/UncancelSubscriptionModal'
import { UnpauseSubscriptionModal } from 'components/AccountRequestsMenu/modals/UnpauseSubscriptionModal'
import { UpdateSubscriptionModal } from 'components/AccountRequestsMenu/modals/UpdateSubscriptionModal'
import { IModalProps, useMenu, useModal } from 'hooks'
import { OpsAccountRequestKind } from 'types'
import { CancelSubscriptionModal } from './modals/CancelSubscriptionModal/CancelSubscriptionModal'
import { ChangeSubscriptionBillingCycleModal } from './modals/ChangeSubscriptionBillingCycleModal'
import { GrantFreeVideoCallModal } from './modals/GrantFreeVideoCallModal'
import { PauseSubscriptionModal } from './modals/PauseSubscriptionModal'

export type IAccountRequestModalProps = IModalProps

export interface IAccountRequestMenuProps {
  children: ReactElement
  accountRequestTitleMap: { [key in OpsAccountRequestKind]: string }
}

const accountRequestModalsLookup = {
  [OpsAccountRequestKind.UpdateSubscription]: UpdateSubscriptionModal,
  [OpsAccountRequestKind.PauseSubscription]: PauseSubscriptionModal,
  [OpsAccountRequestKind.CancelSubscription]: CancelSubscriptionModal,
  [OpsAccountRequestKind.UncancelSubscription]: UncancelSubscriptionModal,
  [OpsAccountRequestKind.Credit]: CreditModal,
  [OpsAccountRequestKind.Refund]: RefundModal,
  [OpsAccountRequestKind.MiscRequest]: MiscRequestModal,
  [OpsAccountRequestKind.DeleteDuplicateAccount]: DeleteDuplicateAccountModal,
  [OpsAccountRequestKind.UnpauseSubscription]: UnpauseSubscriptionModal,
  [OpsAccountRequestKind.OneOffAddon]: OneOffAddonModal,
  [OpsAccountRequestKind.GrantFreeVideoCall]: GrantFreeVideoCallModal,
  [OpsAccountRequestKind.ChangeSubscriptionBillingCycle]: ChangeSubscriptionBillingCycleModal
}

const accountRequestKinds = Object.keys(accountRequestModalsLookup).sort()

export const AccountRequestsMenu = ({
  children,
  accountRequestTitleMap
}: IAccountRequestMenuProps) => {
  const { menuAnchorEl, menuState, openMenu, closeMenu } = useMenu()
  const modalStates = {} as {
    [key in OpsAccountRequestKind]: ReturnType<typeof useModal>
  }
  for (const kind of accountRequestKinds) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    modalStates[kind as keyof typeof accountRequestModalsLookup] = useModal()
  }

  return (
    <>
      {children && cloneElement(children, { onClick: openMenu })}
      <Menu open={menuState} anchorEl={menuAnchorEl} onClose={closeMenu} onClick={closeMenu}>
        {accountRequestKinds.map((kind: keyof typeof accountRequestModalsLookup) => (
          <MenuItem key={kind} value={kind} onClick={modalStates[kind].openModal}>
            {accountRequestTitleMap[kind]}
          </MenuItem>
        ))}
      </Menu>
      {accountRequestKinds.map((kind: keyof typeof accountRequestModalsLookup) => {
        const Modal = accountRequestModalsLookup[kind]
        return Modal && modalStates[kind].modalState && <Modal key={kind} {...modalStates[kind]} />
      })}
    </>
  )
}
