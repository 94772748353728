import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { Button } from '@mui/material'
import { useCanReviewAccountRequest } from 'screens/AccountRequests/hooks/useCanReviewAccountRequest'
import { theme } from 'theme'
import {
  IAccountRequestPayloadUnionPresenterType,
  ICancelSubscriptionResponsePayload,
  OpsAccountRequestKind,
  OpsAccountRequestStatus
} from 'types'

export const useProcessedRequestsActionButtons = (updateAccountRequest: (options: any) => void) => {
  const canReviewAccountRequest = useCanReviewAccountRequest()

  return (
    accountRequestId: string,
    accountRequestKind: OpsAccountRequestKind,
    accountRequestPayload: IAccountRequestPayloadUnionPresenterType
  ) => {
    let accountRequestSubtype

    if (accountRequestKind === OpsAccountRequestKind.CancelSubscription) {
      accountRequestSubtype = (accountRequestPayload as ICancelSubscriptionResponsePayload)
        .cancellationType
    }

    const canReviewRequest = canReviewAccountRequest({
      accountRequestKind,
      accountRequestSubtype
    })
    return canReviewRequest
      ? [
          <Button
            key="review"
            variant="outlined"
            sx={{
              color: theme.palette.primary.light,
              borderColor: theme.palette.primary.light
            }}
            onClick={() =>
              updateAccountRequest({
                variables: { accountRequestId, status: OpsAccountRequestStatus.Reviewed }
              })
            }
            startIcon={<CheckOutlinedIcon />}
          >
            Mark As Reviewed
          </Button>
        ]
      : []
  }
}
