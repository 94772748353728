import { OpsAccountRequestStatus } from 'types'

export const ESCALATED_REQUESTS_DATA_GRID_STATE_KEY =
  'nutrisense:accountRequests:escalated:dataGrid:state'
export const PROCESSED_REQUESTS_DATA_GRID_STATE_KEY =
  'nutrisense:accountRequests:processed:dataGrid:state'
export const REVIEWED_REQUESTS_DATA_GRID_STATE_KEY =
  'nutrisense:accountRequests:reviewed:dataGrid:state'
export const REJECTED_REQUESTS_DATA_GRID_STATE_KEY =
  'nutrisense:accountRequests:rejected:dataGrid:state'
export const FAILED_REQUESTS_DATA_GRID_STATE_KEY =
  'nutrisense:accountRequests:failed:dataGrid:state'

export const ESCALATED_DEFAULT_FILTER = {
  field: 'status',
  operation: 'eq',
  value: OpsAccountRequestStatus.Escalated
}

export const PROCESSED_DEFAULT_FILTER = {
  field: 'status',
  operation: 'eq',
  value: OpsAccountRequestStatus.Processed
}

export const REJECTED_DEFAULT_FILTER = {
  field: 'status',
  operation: 'eq',
  value: OpsAccountRequestStatus.Rejected
}

export const FAILED_DEFAULT_FILTER = {
  field: 'status',
  operation: 'eq',
  value: OpsAccountRequestStatus.Failed
}

export const REVIEWED_DEFAULT_FILTER = {
  field: 'status',
  operation: 'eq',
  value: OpsAccountRequestStatus.Reviewed
}

export const ALL_COLUMNS_MAP = [
  'user.fullName',
  'user.email',
  'escalatedAt',
  'escalatedBy',
  'processedAt',
  'processedBy',
  'rejectedAt',
  'rejectedBy',
  'reviewedAt',
  'reviewedBy',
  'errorMessage',
  'payload.pauseReason',
  'payload.cancellationReason',
  'payload.refundReason.journey',
  'payload.refundReason.reasonOne',
  'payload.refundReason.reasonTwo',
  'priority',
  'kind',
  'payload',
  'notes',
  'actions'
]

export const ESCALATED_COLUMNS_MAP = [
  'user.fullName',
  'user.email',
  'escalatedAt',
  'escalatedBy',
  'payload.pauseReason',
  'payload.cancellationReason',
  'payload.refundReason.journey',
  'payload.refundReason.reasonOne',
  'payload.refundReason.reasonTwo',
  'priority',
  'kind',
  'payload',
  'notes',
  'actions'
]

export const PROCESSED_COLUMNS_MAP = [
  'user.fullName',
  'user.email',
  'escalatedAt',
  'escalatedBy',
  'processedAt',
  'processedBy',
  'payload.pauseReason',
  'payload.cancellationReason',
  'payload.refundReason.journey',
  'payload.refundReason.reasonOne',
  'payload.refundReason.reasonTwo',
  'priority',
  'kind',
  'payload',
  'notes',
  'actions'
]

export const REJECTED_COLUMNS_MAP = [
  'user.fullName',
  'user.email',
  'escalatedAt',
  'escalatedBy',
  'rejectedAt',
  'rejectedBy',
  'payload.pauseReason',
  'payload.cancellationReason',
  'payload.refundReason.journey',
  'payload.refundReason.reasonOne',
  'payload.refundReason.reasonTwo',
  'priority',
  'kind',
  'payload',
  'notes'
]

export const REVIEWED_COLUMNS_MAP = [
  'user.fullName',
  'user.email',
  'escalatedAt',
  'escalatedBy',
  'processedAt',
  'processedBy',
  'reviewedAt',
  'reviewedBy',
  'payload.pauseReason',
  'payload.cancellationReason',
  'payload.refundJourney',
  'payload.refundReason1',
  'payload.refundReason2',
  'priority',
  'kind',
  'payload',
  'notes',
  'actions'
]

export const FAILED_COLUMNS_MAP = [
  'user.fullName',
  'user.email',
  'escalatedAt',
  'escalatedBy',
  'processedAt',
  'processedBy',
  'rejectedAt',
  'rejectedBy',
  'reviewedAt',
  'reviewedBy',
  'errorMessage',
  'payload.pauseReason',
  'payload.cancellationReason',
  'payload.refundReason.journey',
  'payload.refundReason.reasonOne',
  'payload.refundReason.reasonTwo',
  'priority',
  'kind',
  'payload',
  'notes',
  'actions'
]
