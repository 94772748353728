import { buildVars } from 'context/gridVars'
import {
  columns as dashboardTotalsColumns,
  DATA_GRID_STATE_KEY as DASHBOARD_TOTALS_DATA_GRID_STATE_KEY
} from 'screens/CoachesBilling/Dashboard/InvoiceItemTotals/constants'
import {
  columns as dashboardUsersColumns,
  DATA_GRID_STATE_KEY as DASHBOARD_USERS_DATA_GRID_STATE_KEY
} from 'screens/CoachesBilling/Dashboard/InvoiceUsers/constants'

export const dashboardUsersGridVars = buildVars(
  dashboardUsersColumns,
  DASHBOARD_USERS_DATA_GRID_STATE_KEY
)

export const dashboardTotalsGridVars = buildVars(
  dashboardTotalsColumns,
  DASHBOARD_TOTALS_DATA_GRID_STATE_KEY
)
