import { Tooltip, Typography } from '@mui/material'
import { GridCellCheckboxRenderer, GridRenderCellParams } from '@mui/x-data-grid'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { capitalize, lowerCase } from 'lodash'
import { formatDateTime, renderAsUserHomePageLink } from 'utility/dataGrid'
import {
  CoachAssignmentDynamicFilterField,
  CoachAssignmentSortField,
  CoachAssignmentState,
  IUser
} from 'types'

const MemberStateCell = (params: GridRenderCellParams) => {
  const tooltipText =
    lowerCase(params.value) === CoachAssignmentState.Active
      ? 'User has a scheduled video call or had their last video call within the past two weeks'
      : 'User has not had a scheduled call in > 2 weeks'

  return (
    <Tooltip title={tooltipText} placement="right-end">
      <Typography>{capitalize(params.value as string)}</Typography>
    </Tooltip>
  )
}

type IMembers = {
  [K in keyof IUser as `user.${K}`]?: IUser[K]
} & {
  lastCoachMessageDate: string
  state: CoachAssignmentState
  __check__: boolean
}

export const columns: (IDataGridColumn & { field?: keyof IMembers })[] = [
  {
    field: '__check__',
    renderCell: (params) => {
      if (params.row.canSendEngagementMessage) {
        return <GridCellCheckboxRenderer {...params} />
      }

      return (
        <Tooltip
          placement="right"
          title="It has been < 1 week since you sent this user an engagement message"
          followCursor={false}
        >
          <span>
            <GridCellCheckboxRenderer {...params} cellMode="view" />
          </span>
        </Tooltip>
      )
    },
    type: 'singleSelect',
    sortable: false
  },
  {
    field: 'user.fullName',
    headerName: 'User Name',
    width: 250,
    type: 'string',
    valueGetter: ({ row }) => row?.user.fullName,
    renderCell: (params) => renderAsUserHomePageLink(params, (row) => row.user.id)
  },
  {
    field: 'user.email',
    headerName: 'Email',
    width: 250,
    type: 'string',
    valueGetter: ({ row }) => row?.user.email
  },
  {
    field: 'state',
    headerName: 'Member State',
    width: 250,
    type: 'singleSelect',
    valueOptions: [
      { label: 'Active', value: CoachAssignmentState.Active },
      { label: 'Inactive', value: CoachAssignmentState.Inactive }
    ],
    valueGetter: ({ row }) => capitalize(row?.state),
    renderCell: MemberStateCell
  },
  {
    field: 'user.lastAppointmentDate',
    headerName: 'Last Appointment Date',
    width: 250,
    type: 'dateTime',
    valueFormatter: formatDateTime,
    valueGetter: ({ row }) => row?.user.lastAppointmentDate,
    backendSortName: CoachAssignmentSortField.UserLastAppointmentDate,
    backendFilterName: CoachAssignmentDynamicFilterField.UserLastAppointmentDate
  },
  {
    field: 'user.nextAppointmentDate',
    headerName: 'Next Appointment Date',
    width: 250,
    type: 'dateTime',
    valueFormatter: formatDateTime,
    valueGetter: ({ row }) => row?.user.nextAppointmentDate,
    backendSortName: CoachAssignmentSortField.UserNextAppointmentDate,
    backendFilterName: CoachAssignmentDynamicFilterField.UserNextAppointmentDate
  },
  {
    field: 'lastCoachMessageDate',
    headerName: 'Last Message Sent',
    width: 250,
    type: 'dateTime',
    valueFormatter: formatDateTime,
    valueGetter: ({ row }) => row?.lastCoachMessageDate,
    backendSortName: CoachAssignmentSortField.ChatConversationLastCoachMessageSentAt,
    backendFilterName: CoachAssignmentDynamicFilterField.ChatConversationLastCoachMessageSentAt
  }
]
