import { snackBarVisibleVar } from 'context'
import { membersManagementGridVars } from 'context/membersManagement'
import { getCommonListQueryVariables, useReactiveGridVars } from 'utility/dataGrid/commonVariables'
import {
  ChatConversationMessageTemplateKind,
  CoachAssignmentDynamicFilterField,
  CoachAssignmentSupportType,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  AllNutritionistMembersDocument,
  useAllNutritionistMembersQuery,
  useBulkSendScheduledMessageMutation,
  CoachAssignmentState
} from 'types'

const GET_COACH_ASSIGNMENTS_DYNAMIC_FILTERS = [
  {
    field: CoachAssignmentDynamicFilterField.SupportType,
    operation: DynamicFilterItemOperation.Eq,
    value: CoachAssignmentSupportType.VideoChat
  },
  {
    field: CoachAssignmentDynamicFilterField.Last,
    operation: DynamicFilterItemOperation.Eq,
    value: 'true'
  },
  {
    field: CoachAssignmentDynamicFilterField.State,
    operation: DynamicFilterItemOperation.In,
    value: [CoachAssignmentState.Active, CoachAssignmentState.Inactive].join(',')
  }
]

export const useMembers = (currentNutritionist: { value: string; label: string } | null) => {
  const reactiveGridVars = useReactiveGridVars(membersManagementGridVars)
  const variables = getCommonListQueryVariables(reactiveGridVars)

  const {
    data,
    error,
    loading: isLoadingMembers,
    refetch
  } = useAllNutritionistMembersQuery({
    skip: !currentNutritionist,
    variables: {
      ...variables,
      dynamicFilters: [
        {
          items: [
            ...GET_COACH_ASSIGNMENTS_DYNAMIC_FILTERS,
            ...reactiveGridVars.dynamicFilters,
            {
              field: CoachAssignmentDynamicFilterField.CoachId,
              operation: DynamicFilterItemOperation.Eq,
              value: currentNutritionist?.value
            }
          ],
          operator: DynamicFilterOperator.And
        }
      ]
    }
  })

  const [bulkSendMessage, { loading: isSendingMessage }] = useBulkSendScheduledMessageMutation()

  const bulkSendEngagementMessage = async (coachAssignmentIds: string[]) => {
    try {
      await bulkSendMessage({
        variables: {
          coachAssignmentIds,
          kind: ChatConversationMessageTemplateKind.Engagement
        },
        refetchQueries: [AllNutritionistMembersDocument]
      })
      snackBarVisibleVar({
        open: true,
        severity: 'success',
        message: 'Engagement Message sent successfully!'
      })
    } catch (error: any) {
      snackBarVisibleVar({ open: true, severity: 'error', message: error.message })
    }
  }

  return {
    rows: data?.allCoachAssignments.coachAssignments || [],
    rowCount: data?.allCoachAssignments.totalCount,
    isLoadingMembers,
    error,
    refetch,
    bulkSendEngagementMessage,
    isSendingMessage
  }
}
