import { ElementType } from 'react'
import { Box, Typography, TypographyProps } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const GridContainer = styled(Box)({
  height: 'calc(100vh - 200px)',
  width: '100%',
  position: 'relative'
})

export const NutritionistSelectContainer = styled(Box)({
  position: 'absolute',
  right: '15px',
  top: '15px',
  zIndex: 1,
  width: '300px'
})

export const SendMessageConfirmDialogTitle = styled(Typography)<
  TypographyProps & { component?: ElementType }
>({
  flex: 1
})

SendMessageConfirmDialogTitle.defaultProps = {
  ...SendMessageConfirmDialogTitle.defaultProps,
  variant: 'h6',
  component: 'div'
}

export const SendMessageConfirmDialogSubtitle = styled(Typography)<
  TypographyProps & { component?: ElementType }
>({
  marginLeft: '8px',
  color: theme.palette.text.disabled
})
