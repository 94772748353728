import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Chip, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { Loader } from 'components'
import { useConfirm } from 'material-ui-confirm'
import { theme } from 'theme'

export interface IBulkUpdateCoachAssignmentProps {
  bulkSaveCoachAssignmentChanges: () => void
  discardCoachAssignmentChanges: () => void
  savingCoachAssignments: boolean
}

export const BulkUpdateCoachAssignment = ({
  bulkSaveCoachAssignmentChanges,
  discardCoachAssignmentChanges,
  savingCoachAssignments
}: IBulkUpdateCoachAssignmentProps) => {
  const confirm = useConfirm()

  const bulkUpdateHandler = async () => {
    const { confirmed } = await confirm({
      description: 'Continuing will create notifications for newly assigned dietitians'
    })

    if (!confirmed) {
      return
    }
    await bulkSaveCoachAssignmentChanges()
  }

  return (
    <>
      <Chip
        sx={{ marginLeft: 10, marginRight: 1, color: theme.palette['danger'].main }}
        label="Unsaved Coach Assignments!"
      />
      {!savingCoachAssignments && (
        <>
          <IconButton size="small" onClick={bulkUpdateHandler} disabled={savingCoachAssignments}>
            <CheckIcon sx={{ color: theme.palette['brand-primary'].main }} />
          </IconButton>
          <IconButton size="small" onClick={discardCoachAssignmentChanges}>
            <CloseIcon sx={{ color: theme.palette['danger'].main }} />
          </IconButton>
        </>
      )}
      {savingCoachAssignments && (
        <Box>
          <Loader />
        </Box>
      )}
    </>
  )
}
