export const parseUtcDate = (dateString: string) => {
  if (!dateString) return null
  const date = new Date(dateString)
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export const parseUtcDateTime = (dateString: string) => {
  if (!dateString) return null
  const date = new Date(dateString)
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  )
}

export const isDate = (date: string) => !isNaN(new Date(date).getDate())
