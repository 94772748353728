import { GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { parseUtcDate as doParseUtcDate } from 'utility/dateParsers'
import { formatWithSubscriptionStatusMask } from 'utility/formatWithSubscriptionStatusMask'
import { numberToCurrency } from 'utility/numberToCurrency'
import { ISO_DATE_FORMAT, MM_DD_YYYY } from 'utility/timeFormats'

type AbbreviatedDayOfWeek = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun'

const DAY_OF_WEEK_ORDER: { [K in AbbreviatedDayOfWeek]: number } = {
  Mon: 0,
  Tue: 1,
  Wed: 2,
  Thu: 3,
  Fri: 4,
  Sat: 5,
  Sun: 6
}

const formatDateByPattern = (value: string | Date, formatPattern: string) => {
  if (!value) return null
  return format(typeof value === 'string' ? new Date(value) : value, formatPattern)
}

export const formatDate = ({ value }: { value: string | Date }) => {
  return formatDateByPattern(value, MM_DD_YYYY)
}

export const formatDateTime = ({ value }: GridValueFormatterParams<string | Date>) => {
  return formatDateByPattern(value, ISO_DATE_FORMAT)
}

export const parseUtcDate = ({ value }: GridValueGetterParams) => {
  return doParseUtcDate(value)
}

export const formatStartCase = ({ value }: GridValueFormatterParams<string | string[]>) => {
  const values = Array.isArray(value) ? value : [value]

  return values.map(startCase).join(', ')
}

export const formatSubscriptionStatus = ({ value }: GridValueFormatterParams<string>) => {
  return formatWithSubscriptionStatusMask(value)
}

export const formatAbbreviatedDayOfWeek = ({
  value
}: GridValueFormatterParams<string | string[]>) => {
  const dayOfWeekValues = Array.isArray(value) ? value : [value]
  const abbreviations = dayOfWeekValues.map(
    (value) => startCase(value).slice(0, 3) as AbbreviatedDayOfWeek
  )

  return abbreviations.sort((a, b) => DAY_OF_WEEK_ORDER[a] - DAY_OF_WEEK_ORDER[b]).join(', ')
}

export const formatCurrency = ({ value }: GridValueFormatterParams<number>) => {
  return numberToCurrency(value)
}
