import { useMemo, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { AppToolbar, CommonRightAdornment, DataGrid } from 'components'
import { DATA_GRID_STATE_KEY, membersManagementGridVars } from 'context/membersManagement'
import { useAuthorizedFeature } from 'hooks/useAuthorizedFeature'
import { getAllCoachFilters } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/allCoachesFilters'
import {
  HealthPortalFeature,
  IUser,
  useAdminPanelAllCoachesQuery,
  useCurrentAdminQuery
} from 'types'
import { BulkUpdateProvider } from './BulkUpdateProvider'
import { columns } from './columns'
import { SendMessageConfirmDialog } from './SendMessageConfirmDialog'
import { GridContainer, NutritionistSelectContainer } from './styles'
import { useMembers } from './useMembers'
import { buildNutritionistOption } from './utils'

export const MembersManagement = () => {
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()
  const nutritionistSelectionAllowed = useAuthorizedFeature(
    HealthPortalFeature.MembersManagementNutritionistSelection
  )

  const { data: allNutritionistsData, loading } = useAdminPanelAllCoachesQuery({
    skip: !nutritionistSelectionAllowed,
    variables: getAllCoachFilters(),
    fetchPolicy: 'network-only'
  })

  const [currentNutritionist, setCurrentNutritionist] = useState(() =>
    buildNutritionistOption(currentAdmin as IUser)
  )
  const allNutritionistsOptions = useMemo(() => {
    return allNutritionistsData?.allUsers.users.map((nutritionist) =>
      buildNutritionistOption(nutritionist as IUser)
    )
  }, [allNutritionistsData])

  const {
    rows,
    rowCount,
    isLoadingMembers,
    error,
    refetch,
    isSendingMessage,
    bulkSendEngagementMessage
  } = useMembers(currentNutritionist)

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [selectedCoachAssignmentIds, setSelectedCoachAssignmentIds] = useState<string[]>([])

  const handleConfirmBulkSend = (coachAssignmentIds: string[]) => {
    setSelectedCoachAssignmentIds(coachAssignmentIds)
    setOpenConfirmDialog(true)
  }

  return (
    <>
      <AppToolbar rightAdornment={<CommonRightAdornment />} />
      <GridContainer>
        {nutritionistSelectionAllowed && (
          <NutritionistSelectContainer>
            <Autocomplete
              sx={{ width: '100%' }}
              options={allNutritionistsOptions || []}
              isOptionEqualToValue={(userA, userB) => userA?.value === userB?.value}
              loading={loading}
              getOptionLabel={(option) => option?.label || ''}
              clearOnEscape
              value={currentNutritionist}
              onChange={(_, newValue) => setCurrentNutritionist(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" placeholder="Select a Nutritionist" />
              )}
            />
          </NutritionistSelectContainer>
        )}
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoadingMembers}
          error={error}
          gridVars={membersManagementGridVars}
          refresh={refetch}
          rowCount={rowCount}
          rowHeight="auto"
          checkboxSelection
          bulkUpdateVisible
          bulkUpdate={handleConfirmBulkSend}
          BulkUpdateSelectedRows={BulkUpdateProvider}
          stateKey={DATA_GRID_STATE_KEY}
          isRowSelectable={({ row }) => row.canSendEngagementMessage}
        />
      </GridContainer>
      <SendMessageConfirmDialog
        coachAssignmentIds={selectedCoachAssignmentIds}
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        loading={isSendingMessage}
        onSubmit={bulkSendEngagementMessage}
      />
    </>
  )
}
