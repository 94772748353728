import { Box, styled } from '@mui/material'

export const WebMembersContainerStyled = styled(Box)({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white'
})

export const ContentContainerStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '90%',
  maxWidth: '1200px'
})

export const WebMembersFrameContainerStyled = styled(Box)({
  width: '100%',
  height: 'calc(100vh - 120px)',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})
