import { addMinutes, format, getDay, parse, startOfWeek } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { formatInTimeZone } from 'date-fns-tz'
import { startCase } from 'lodash'
import { dateFnsLocalizer } from 'react-big-calendar'
import {
  IAppointment,
  IInsurancePolicy,
  IInsurancePolicyState,
  InsuranceBerryStreetAppointmentChargeType,
  InsuranceBerryStreetAppointmentType,
  UserInsurancePolicyStateKind
} from 'types'
import { EventStatuses, CustomEventStatuses } from './EventStatuses'
import { IEvent } from './types'

const durationLookup = {
  [InsuranceBerryStreetAppointmentType.Initial]: 90,
  [InsuranceBerryStreetAppointmentType.Initial_60]: 60,
  [InsuranceBerryStreetAppointmentType.Followup_30]: 30,
  [InsuranceBerryStreetAppointmentType.Followup_45]: 45,
  [InsuranceBerryStreetAppointmentType.Followup]: 60,
  [InsuranceBerryStreetAppointmentType.Followup_90]: 90,
  [InsuranceBerryStreetAppointmentType.NoneCovered]: 30,
  [InsuranceBerryStreetAppointmentType.DppSession]: 60,
  [InsuranceBerryStreetAppointmentType.DpWebinarSession]: 60
}

const PENDING_INSURANCE_POLICY_STATE_KINDS = [
  UserInsurancePolicyStateKind.Created,
  UserInsurancePolicyStateKind.InReview,
  UserInsurancePolicyStateKind.Hold
]

const getStatus = ({ meetingStatus, insurancePolicy }: IAppointment) => {
  if (meetingStatus && EventStatuses[meetingStatus]) return EventStatuses[meetingStatus]
  if (
    insurancePolicy?.lastState &&
    PENDING_INSURANCE_POLICY_STATE_KINDS.includes(insurancePolicy.lastState.kind)
  )
    return CustomEventStatuses.pendingConfirmed
  return CustomEventStatuses.confirmed
}

export const getMeetingEnd = (
  meetingAt: Date,
  appointmentType: InsuranceBerryStreetAppointmentType
) => {
  return addMinutes(meetingAt, durationLookup[appointmentType])
}

export const toEvent = (appointment: IAppointment): IEvent => {
  const meetingAt = new Date(appointment.meetingAt)

  return {
    id: appointment.id,
    title: appointment.user.fullName,
    start: meetingAt,
    end: getMeetingEnd(meetingAt, appointment.appointmentType!),
    status: getStatus(appointment),
    userId: appointment.user.id,
    durationMinutes: durationLookup[appointment.appointmentType!]
  }
}

export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: {
    'en-US': enUS
  }
})

export const dayPropGetter = () => {
  return {
    style: {
      backgroundColor: 'transparent'
    }
  }
}

export const eventPropGetter = (event: IEvent) => {
  return {
    style: {
      backgroundColor: event.status.backgroundColor,
      borderColor: event.status.borderColor
    }
  }
}

const TIME_FORMAT = 'h:mmaaa'
const DATE_FORMAT = 'EEEE LLL do'

export const appointmentTimeDescription = ({
  meetingAt: meetingAtString,
  appointmentType,
  user
}: IAppointment) => {
  const meetingAt = new Date(meetingAtString)
  const meetingEnd = getMeetingEnd(meetingAt, appointmentType!)
  const userTimeZone = user.timeZone

  return `${format(meetingAt, TIME_FORMAT)} - ${format(
    meetingEnd,
    TIME_FORMAT
  )} (${formatInTimeZone(meetingAt, userTimeZone, TIME_FORMAT)} - ${formatInTimeZone(
    meetingEnd,
    userTimeZone,
    TIME_FORMAT
  )})`
}

export const historyAppointmentTimeDescription = ({
  meetingAt: meetingAtString,
  appointmentType,
  meetingStatus
}: IAppointment) => {
  const meetingAt = new Date(meetingAtString)
  const meetingEnd = getMeetingEnd(meetingAt, appointmentType!)

  return `${format(meetingAt, DATE_FORMAT)}, ${format(meetingAt, TIME_FORMAT)} - ${format(
    meetingEnd,
    TIME_FORMAT
  )}${meetingStatus ? ` (${startCase(meetingStatus)})` : ''}`
}

const NON_INSURANCE_COVERED_CHARGE_TYPES = [
  InsuranceBerryStreetAppointmentChargeType.Free,
  InsuranceBerryStreetAppointmentChargeType.Paid
]
const INITIAL_APPOINTMENT_TYPES = [
  InsuranceBerryStreetAppointmentType.Initial,
  InsuranceBerryStreetAppointmentType.Initial_60
]

export const appointmentDescription = ({ chargeType, appointmentType }: IAppointment) => {
  if (!chargeType || !appointmentType) return ''
  if (NON_INSURANCE_COVERED_CHARGE_TYPES.includes(chargeType)) return startCase(chargeType)

  const appointmentTypeDescription = INITIAL_APPOINTMENT_TYPES.includes(appointmentType)
    ? 'Initial'
    : 'Follow up'
  return `${appointmentTypeDescription} insurance covered`
}

export const callsUsedDescription = (
  callsUsed: number,
  { unitsAuthorized, unitsLeft }: IInsurancePolicy
) => {
  const callsLeft =
    unitsLeft === -1 ? 'Unlimited' : unitsAuthorized ? `${unitsAuthorized - callsUsed} left` : ''
  return `${callsUsed} call${callsUsed !== 1 ? 's' : ''} completed (${callsLeft})`
}

const insurancePolicyStateUILookup = {
  [UserInsurancePolicyStateKind.Created]: {
    bullet: '🟡',
    description: 'Created'
  },
  [UserInsurancePolicyStateKind.InReview]: {
    bullet: '🟡',
    description: 'Benefits check pending'
  },
  [UserInsurancePolicyStateKind.Hold]: {
    bullet: '🟡',
    description: 'HOLD'
  },
  [UserInsurancePolicyStateKind.Canceled]: {
    bullet: '🔴',
    description: 'Canceled'
  },
  [UserInsurancePolicyStateKind.CanceledInsuranceNotAccepted]: {
    bullet: '🔴',
    description: 'Insurance is not accepted'
  },
  [UserInsurancePolicyStateKind.CanceledNoProvidersInn]: {
    bullet: '🔴',
    description: 'No insurance coverage - No providers in network'
  },
  [UserInsurancePolicyStateKind.NotEligible]: {
    bullet: '🔴',
    description: 'No insurance coverage'
  },
  [UserInsurancePolicyStateKind.NotSupported]: {
    bullet: '🔴',
    description: 'Not supported'
  },
  [UserInsurancePolicyStateKind.Eligible]: {
    bullet: '🟢',
    description: 'Member covered'
  },
  [UserInsurancePolicyStateKind.EligiblePatientResponsibility]: {
    bullet: '🟢',
    description: 'Member covered - Patient responsibility'
  },
  [UserInsurancePolicyStateKind.EligiblePreAuth]: {
    bullet: '🟢',
    description: 'Member covered - Pre-authorization required'
  }
}

export const insurancePolicyStateDescription = (state: IInsurancePolicyState | undefined) => {
  if (!state) return '⚪ No insurance submitted'

  const stateKindUI = insurancePolicyStateUILookup[state.kind]
  return `${stateKindUI.bullet}️ ${stateKindUI.description}${
    state.reason ? ` - ${state.reason}` : ''
  }`
}
