export enum FeatureFlags {
  MemberNotes = 'member_notes',
  MembersManagement = 'members_management',
  MobileAppPresentationMode = 'mobile_app_presentation_mode',
  NewChatUpload = 'new_chat_upload',
  NutritionistHome = 'nutritionist_home',
  TicketCommentSupport = 'ticket_comment_support',
  UserGoals = 'user_goals',
  UserDailyActions = 'health_portal_user_daily_actions',
  WebMembersImpersonate = 'web_members_impersonate'
}
