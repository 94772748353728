import { Typography, TypographyProps } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'
import { CoachBillingInvoiceUserBillingPeriodStates } from 'types'

export const BillingInvoiceUserStateText = styled(Typography)<
  TypographyProps & {
    state: CoachBillingInvoiceUserBillingPeriodStates
  }
>(({ state }) => ({
  color:
    state === CoachBillingInvoiceUserBillingPeriodStates.Inactive
      ? theme.palette.text.disabled
      : theme.palette.text.primary
}))
