import CloseIcon from '@mui/icons-material/Close'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import ReplayIcon from '@mui/icons-material/Replay'
import { CircularProgress, IconButton } from '@mui/material'
import { snackBarVisibleVar } from 'context'
import { useStreamChatChannel, useStreamChatClient } from 'hooks'
import { useConfirm } from 'material-ui-confirm'
import { ChatbotWindow } from 'screens/Chatbot/ChatbotWindow'
import { ICustomActions } from 'screens/Chatbot/Custom/types'
import { useCreateChatbotDelimiterMutation, useCurrentAdminQuery } from 'types'
import { ChatPanelContainer, PanelContentContainer, PanelHeader, PanelTitle } from './styles'

interface IChatPanelProps {
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  onShowSources: (messageId?: number) => void
  onClose: () => void
}

const ConfirmClearConversationContextContent = () => (
  <>
    Do you want to manually clear the context of the conversation?
    <p>You&apos;ll still be able to access previous messages.</p>
  </>
)

export const ChatPanel = ({ expanded, setExpanded, onShowSources, onClose }: IChatPanelProps) => {
  const confirm = useConfirm()

  const { data: currentAdminData } = useCurrentAdminQuery()
  const currentAdmin = currentAdminData?.currentAdmin

  const client = useStreamChatClient({
    userId: currentAdmin?.id,
    userName: currentAdmin?.fullName,
    userAvatar: currentAdmin?.avatar
  })

  const channel = useStreamChatChannel({
    streamChatClient: client,
    channelType: 'chatbot-web',
    channelName: 'Health AI'
  })

  const [createChatbotDelimiter, { loading: creatingDelimiter }] =
    useCreateChatbotDelimiterMutation({
      onError: (error) => {
        snackBarVisibleVar({ open: true, message: error.message })
      }
    })

  const confirmResetConversationContext = async (providerConversationId: string) => {
    createChatbotDelimiter({
      variables: { providerConversationId }
    })
  }

  const handleResetConversationContext = async () => {
    if (!channel?.id || creatingDelimiter) {
      return
    }
    const { confirmed } = await confirm({
      title: 'Clear the conversation',
      content: <ConfirmClearConversationContextContent />,
      confirmationText: 'Clear conversation'
    })

    if (!confirmed) {
      return
    }

    confirmResetConversationContext(channel.id)
  }

  const customActions: ICustomActions = {
    'Show Sources': async (message) => {
      await onShowSources?.(message.nutrisense_id as number | undefined)
    }
  }

  return (
    <ChatPanelContainer expanded={expanded}>
      <PanelHeader>
        <PanelTitle>Health Portal AI</PanelTitle>
        <IconButton size="small" onClick={handleResetConversationContext}>
          {creatingDelimiter ? <CircularProgress /> : <ReplayIcon />}
        </IconButton>
        <IconButton size="small" onClick={() => setExpanded(!expanded)}>
          {expanded ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
        </IconButton>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </PanelHeader>
      <PanelContentContainer>
        <ChatbotWindow
          client={client}
          channel={channel}
          customActions={customActions}
          interactionEnabled
        />
      </PanelContentContainer>
    </ChatPanelContainer>
  )
}
