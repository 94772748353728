import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { snackBarVisibleVar } from 'context'
import { useAuthorizedFeature } from 'hooks'
import { useForm } from 'react-hook-form'
import {
  HealthPortalFeature,
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useUpdateAccountRequestMutation
} from 'types'

interface IForm {
  priority: OpsAccountRequestPriority
  note: string
}

export const GrantFreeVideoCallModal = ({ closeModal, modalState }: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { handleSubmit, control, register, reset } = useForm<IForm>({
    mode: 'onTouched',
    defaultValues: {
      priority: OpsAccountRequestPriority.Regular,
      note: ''
    }
  })

  const processAuthorized = useAuthorizedFeature(
    HealthPortalFeature.SupportAccountRequestsProcessGrantFreeVideoCall
  )

  const onClose = () => {
    reset()
    closeModal()
  }

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  if (!currentUser) {
    return null
  }

  const handler = async (formData: IForm) => {
    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.GrantFreeVideoCall,
        priority: formData.priority,
        notes: formData.note
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  return (
    <AccountRequestModal<IForm>
      register={register}
      modalTitle="Grant Free Video Call"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(handler)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    />
  )
}
