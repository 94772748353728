import { useState } from 'react'
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField
} from '@mui/material'

import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { SearchUser } from 'components/SearchUser'
import { snackBarVisibleVar } from 'context'
import { useAuthorizedFeature } from 'hooks'
import { useConfirm } from 'material-ui-confirm'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import {
  HealthPortalFeature,
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCalculateDuplicateAccountCreditQuery,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useUpdateAccountRequestMutation
} from 'types'

interface IDeleteDuplicateAccountForm {
  duplicateUserId: string
  replaceWithDuplicateUserEmail: boolean
  creditAmount: number
  priority: OpsAccountRequestPriority
  note: string
}

export const DeleteDuplicateAccountModal = ({
  closeModal,
  modalState
}: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const [creditAmountInCents, setCreditAmountInCents] = useState<number>(0)
  const [duplicateUserId, setDuplicateUserId] = useState<string | null>(null)
  const { handleSubmit, control, register, reset, formState } =
    useForm<IDeleteDuplicateAccountForm>({
      mode: 'onTouched',
      defaultValues: {
        duplicateUserId: '',
        replaceWithDuplicateUserEmail: false,
        creditAmount: 0,
        priority: OpsAccountRequestPriority.Regular,
        note: ''
      }
    })

  const processAuthorized = useAuthorizedFeature(
    HealthPortalFeature.SupportAccountRequestsProcessDeleteDuplicateAccount
  )

  useCalculateDuplicateAccountCreditQuery({
    variables: { userId: duplicateUserId ?? '' },
    skip: !duplicateUserId,
    onCompleted: (data) => {
      setCreditAmountInCents(data.calculateDuplicateAccountCredit.creditAmountInCents)
    }
  })

  const onClose = () => {
    reset()
    closeModal()
  }

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const confirmModal = useConfirm()

  if (!currentUser) {
    return null
  }

  const confirmFormSubmit = async (formData: IDeleteDuplicateAccountForm) => {
    const { confirmed } = await confirmModal({
      description:
        'Are you sure you want to proceed with PERMANENTLY DELETING the duplicate account?'
    })

    if (!confirmed) {
      return
    }

    updateSubscriptionHandler(formData)
  }

  const updateSubscriptionHandler = async (formData: IDeleteDuplicateAccountForm) => {
    if (!duplicateUserId) return

    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.DeleteDuplicateAccount,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.DeleteDuplicateAccount,
          deleteDuplicateAccount: {
            duplicateUserId,
            replaceWithDuplicateUserEmail: formData.replaceWithDuplicateUserEmail,
            creditAmountInCents: Number(formData.creditAmount * 100) || creditAmountInCents || 0
          }
        }
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  const handleFormSubmit = (formData: IDeleteDuplicateAccountForm) => {
    if (!duplicateUserId) {
      snackBarVisibleVar({
        open: true,
        message: 'Select a duplicate account to proceed'
      })

      return
    }
    if (duplicateUserId === currentUser.id) {
      snackBarVisibleVar({
        open: true,
        message: 'You cannot select the current account as a duplicate '
      })

      return
    }

    confirmFormSubmit(formData)
  }

  const innerForm = (
    <>
      {!!creditAmountInCents && (
        <FormControl margin="dense" fullWidth>
          <Alert severity="info" variant="outlined">
            Based on existing charges on the duplicate account the member with email:{' '}
            {currentUser.email} will be credited: ${creditAmountInCents / 100}
          </Alert>
        </FormControl>
      )}
      <FormControl variant="outlined" margin="dense" fullWidth>
        <SearchUser onUserSelect={(user) => setDuplicateUserId(user.id)} />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <Controller
          name="replaceWithDuplicateUserEmail"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              }
              label="Substitute current account's email with duplicate account email?"
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <Controller
          name="creditAmount"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...rest } }) => (
            <NumericFormat
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              getInputRef={ref}
              customInput={TextField}
              variant="outlined"
              fullWidth
              label="Credit Amount"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              error={!!formState.errors.creditAmount}
              {...rest}
            />
          )}
        />
      </FormControl>
    </>
  )

  return (
    <AccountRequestModal<IDeleteDuplicateAccountForm>
      register={register}
      modalTitle="Delete Duplicate Account"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(handleFormSubmit)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    >
      {innerForm}
    </AccountRequestModal>
  )
}
