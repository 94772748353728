import { format } from 'date-fns'
import { startCase } from 'lodash'
import { numericFormatter } from 'react-number-format'
import { DATE_TIME_WITH_SHORT_ZONE_FORMAT, YYYY_MM_DD } from 'utility/timeFormats'
import {
  IAccountRequest,
  ICancelSubscriptionResponsePayload,
  IChangeSubscriptionBillingCycleResponsePayload,
  ICreditResponsePayload,
  IDeleteDuplicateAccountResponsePayload,
  IMiscRequestResponsePayload,
  IOneOffAddonResponsePayload,
  IPauseSubscriptionResponsePayload,
  IRefundResponsePayload,
  IUncancelSubscriptionResponsePayload,
  IUnpauseSubscriptionResponsePayload,
  IUpdateSubscriptionResponsePayload,
  IUpsertLimitedSupportResponsePayload,
  OpsAccountRequestKind
} from 'types'

const describeUpdateSubscription = ({
  oldProduct,
  nextProduct
}: IUpdateSubscriptionResponsePayload) =>
  `Member updated their subscription ${
    oldProduct ? `from ${startCase(oldProduct)} ` : ''
  }to ${startCase(nextProduct)}`

const describePauseSubscription = ({ cycleCount }: IPauseSubscriptionResponsePayload) =>
  `Member paused their subscription for next ${cycleCount} cycles`

const describeCredit = ({ centsAmount }: ICreditResponsePayload) =>
  `Member credit processed of $${numericFormatter(String(centsAmount / 100), {
    thousandSeparator: true,
    decimalScale: 2
  })}`

const describeOneOffAddon = ({ product }: IOneOffAddonResponsePayload) =>
  `${startCase(product)} added`

const describeRefund = ({ amountInCents, chargeId }: IRefundResponsePayload) =>
  `A refund of $${numericFormatter(String(amountInCents / 100), {
    thousandSeparator: true,
    decimalScale: 2
  })} for Charge ID: ${chargeId} was added`

const describeCancellation = ({ subscriptionId }: ICancelSubscriptionResponsePayload) =>
  `Subscription with ID ${subscriptionId} was successfully cancelled`

const describeMiscRequest = ({ requestDetails }: IMiscRequestResponsePayload) =>
  `Misc Request was successfully executed: ${requestDetails}`

const describeChangeSubscriptionBillingCycle = ({
  billingCycleAnchor
}: IChangeSubscriptionBillingCycleResponsePayload) =>
  `Member charged date changed to ${format(
    new Date(billingCycleAnchor),
    DATE_TIME_WITH_SHORT_ZONE_FORMAT
  )}`

const describeDeleteDuplicateAccountRequest = ({
  duplicateUserId,
  replaceWithDuplicateUserEmail,
  creditAmountInCents
}: IDeleteDuplicateAccountResponsePayload) =>
  `Duplicate account with ID ${duplicateUserId} was successfully deleted.
  ${replaceWithDuplicateUserEmail ? 'Email was replaced' : ''}
  ${
    creditAmountInCents
      ? `Credit of $${numericFormatter(String(creditAmountInCents / 100), {
          thousandSeparator: true,
          decimalScale: 2
        })} was added`
      : ''
  }`

const describeUnpauseSubscriptionAccountRequest = ({
  subscriptionId,
  resumeDate
}: IUnpauseSubscriptionResponsePayload) => {
  const resumeAtDescription = resumeDate
    ? `on: ${format(new Date(resumeDate), YYYY_MM_DD)}`
    : 'shortly'
  return `Subscription with ID ${subscriptionId} will be resumed ${resumeAtDescription}`
}

const describeUncancelSubscription = ({ subscriptionId }: IUncancelSubscriptionResponsePayload) =>
  `Subscription with ID ${subscriptionId} was successfully uncancelled`

const describeUpsertLimitedSupport = ({
  limitedSupportSubscriptionId,
  limitedSupportCancelAt
}: IUpsertLimitedSupportResponsePayload) => {
  const subscriptionIdSlug = limitedSupportSubscriptionId
    ? `with ID ${limitedSupportSubscriptionId}`
    : ''
  return `Complimentary support ${subscriptionIdSlug} will be cancelled on ${format(
    new Date(limitedSupportCancelAt),
    DATE_TIME_WITH_SHORT_ZONE_FORMAT
  )}`
}

const describeGrantFreeVideoCall = () => 'Member was granted free video call'

const KIND_TO_DESCRIBER_LOOKUP: {
  [key in OpsAccountRequestKind]: (payload: any) => string | undefined
} = {
  [OpsAccountRequestKind.CancelSubscription]: describeCancellation,
  [OpsAccountRequestKind.ChangeSubscriptionBillingCycle]: describeChangeSubscriptionBillingCycle,
  [OpsAccountRequestKind.Credit]: describeCredit,
  [OpsAccountRequestKind.DeleteDuplicateAccount]: describeDeleteDuplicateAccountRequest,
  [OpsAccountRequestKind.GrantFreeVideoCall]: describeGrantFreeVideoCall,
  [OpsAccountRequestKind.MiscRequest]: describeMiscRequest,
  [OpsAccountRequestKind.OneOffAddon]: describeOneOffAddon,
  [OpsAccountRequestKind.PauseSubscription]: describePauseSubscription,
  [OpsAccountRequestKind.Refund]: describeRefund,
  [OpsAccountRequestKind.UncancelSubscription]: describeUncancelSubscription,
  [OpsAccountRequestKind.UnpauseSubscription]: describeUnpauseSubscriptionAccountRequest,
  [OpsAccountRequestKind.UpdateSubscription]: describeUpdateSubscription,
  [OpsAccountRequestKind.UpsertLimitedSupport]: describeUpsertLimitedSupport
}

export const describeProcessedAccountRequest = (accountRequest: IAccountRequest) =>
  KIND_TO_DESCRIBER_LOOKUP[accountRequest.kind]?.(accountRequest.payload) || accountRequest.kind
