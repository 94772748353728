import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import PeopleOutline from '@mui/icons-material/PeopleOutline'
import { IconButton, Tooltip } from '@mui/material'
import { NutritionistNextCall } from 'components/NutritionistNextCall'
import { FeatureFlags } from 'featureFlags.types'
import { useFeatureFlag } from 'hooks'
import { LocationPaths } from 'location.types'
import { useNavigate } from 'react-router-dom'

export const NutritionistLeftAdornment = () => {
  const navigate = useNavigate()
  const membersManagementFeatureEnabled = useFeatureFlag(FeatureFlags.MembersManagement)

  return (
    <>
      <Tooltip title="Daily Appointments">
        <IconButton edge="start" onClick={() => navigate(`${LocationPaths.NutritionistHome}/`)}>
          <CalendarTodayOutlinedIcon />
        </IconButton>
      </Tooltip>
      {membersManagementFeatureEnabled && (
        <Tooltip title="Members Management">
          <IconButton edge="start" onClick={() => navigate(`${LocationPaths.MembersManagement}/`)}>
            <PeopleOutline />
          </IconButton>
        </Tooltip>
      )}
      <NutritionistNextCall />
    </>
  )
}
