import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { formatCurrency, formatStartCase } from 'utility/dataGrid'
import { ICoachBillingInvoiceItemTotal } from 'types'

type IBillingInvoiceUsers = {
  [K in keyof ICoachBillingInvoiceItemTotal as K]?: ICoachBillingInvoiceItemTotal[K]
}

export const columns: (IDataGridColumn & {
  field: keyof IBillingInvoiceUsers
})[] = [
  {
    field: 'kind',
    headerName: 'Description',
    width: 240,
    type: 'string',
    filterable: false,
    sortable: false,
    valueFormatter: formatStartCase
  },
  {
    field: 'count',
    headerName: 'Count',
    width: 100,
    type: 'number',
    filterable: false,
    sortable: false
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 100,
    type: 'number',
    filterable: false,
    sortable: false
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 100,
    type: 'number',
    filterable: false,
    sortable: false,
    valueFormatter: formatCurrency
  }
]
