import { Stack } from '@mui/material'
import { AppToolbar, CommonRightAdornment } from 'components'
import { Outlet } from 'react-router-dom'

export const AdminPanelLayout = () => {
  return (
    <Stack height="100%">
      <AppToolbar rightAdornment={<CommonRightAdornment />} />
      <Stack flexDirection="column" gap={1} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  )
}
