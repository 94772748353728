import { Suspense } from 'react'
import { Stack, Toolbar, Tabs, Tab } from '@mui/material'
import {
  CoachesBillingDashboardResource,
  CoachesBillingResource
} from 'coachesBillingResource.types'
import { AuthorizedFeature, Loader } from 'components'
import { LocationPaths } from 'location.types'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { getBillingPeriodSearchParams } from 'screens/CoachesBilling/BillingPeriodSelect'
import { HealthPortalFeature } from 'types'

const dashboardResources: { resource: CoachesBillingDashboardResource; label: string }[] = [
  { resource: CoachesBillingDashboardResource.Users, label: 'Coach Invoicing' },
  { resource: CoachesBillingDashboardResource.Totals, label: 'Billing Period Summary' }
]

export const DashboardLayout = () => {
  const location = useLocation()
  const searchParams = getBillingPeriodSearchParams()

  const tabs = dashboardResources.map(({ resource, label }) => (
    <Tab key={resource} label={label} component={NavLink} to={`${resource}${searchParams}`} />
  ))

  const tabValue = dashboardResources.findIndex(({ resource }) =>
    location.pathname.match(
      new RegExp(`^${LocationPaths.Coaches}/${CoachesBillingResource.BillingDashboard}/${resource}`)
    )
  )

  return (
    <AuthorizedFeature feature={HealthPortalFeature.CoachesBillingDashboard}>
      <Stack height="100%">
        <Toolbar>
          <Tabs value={tabValue}>{tabs}</Tabs>
        </Toolbar>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Stack>
    </AuthorizedFeature>
  )
}
