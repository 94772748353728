import { ReactNode } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'

import { startCase } from 'lodash'
import { Control, Controller, Path, UseFormRegister } from 'react-hook-form'
import { OpsAccountRequestPriority } from 'types'

interface IFieldValues {
  priority: OpsAccountRequestPriority
  note: string
}

interface IAccountRequestModalProps<T extends IFieldValues> {
  register: UseFormRegister<T>
  control: Control<T>
  onClose: () => void
  handleSubmit?: () => void
  modalState: boolean
  loading: boolean
  modalTitle: string
  callToActionText: string
  children?: ReactNode
}

export const AccountRequestModal = <T extends IFieldValues>({
  children,
  register,
  modalTitle,
  control,
  modalState,
  onClose,
  handleSubmit,
  loading,
  callToActionText
}: IAccountRequestModalProps<T>) => {
  return (
    <Dialog open={modalState} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {modalTitle}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {children}
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel>Priority</InputLabel>
            <Controller
              name={'priority' as Path<T>}
              control={control}
              render={({ field }) => (
                <Select {...field} label="Priority">
                  {Object.values(OpsAccountRequestPriority).map((priority) => (
                    <MenuItem key={priority} value={priority}>
                      {startCase(priority)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <FormControl variant="outlined" margin="dense" fullWidth>
            <TextField
              label="Add a note (optional)"
              fullWidth
              multiline
              minRows={2}
              {...register('note' as Path<T>)}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton variant="contained" onClick={handleSubmit} loading={loading}>
          {callToActionText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
