import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar } from '@mui/material'
import { snackBarVisibleVar } from 'context'
import pluralize from 'pluralize'
import { CloseIcon } from 'stream-chat-react'
import { SendMessageConfirmDialogSubtitle, SendMessageConfirmDialogTitle } from './styles'

interface IConfirmDialogProps {
  coachAssignmentIds: string[]
  open: boolean
  onClose: () => void
  loading: boolean
  onSubmit: (coachAssignmentIds: string[]) => Promise<void>
}

export const SendMessageConfirmDialog = ({
  open,
  onClose,
  onSubmit,
  coachAssignmentIds,
  loading
}: IConfirmDialogProps) => {
  const handleSubmit = async () => {
    try {
      await onSubmit(coachAssignmentIds)
      onClose()
    } catch (error: any) {
      snackBarVisibleVar({ open: true, message: error.message })
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <Toolbar>
        <SendMessageConfirmDialogTitle>
          Send Engagement Message
          <SendMessageConfirmDialogSubtitle component="span">
            {pluralize('member', coachAssignmentIds.length, true)} selected
          </SendMessageConfirmDialogSubtitle>
        </SendMessageConfirmDialogTitle>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <DialogContent>
        Are you sure you want to send the Engagement Message to all selected users?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={handleSubmit}>
          Send Message
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
