import { useEffect } from 'react'
import { env } from 'config'
import {
  CLEAR_PRIVACY_MODE_LOCAL_STORAGE_KEY,
  PRESENTATION_WINDOW_COUNT_STORAGE_KEY,
  PRIVACY_MODE_LOCAL_STORAGE_KEY
} from 'hooks/usePrivacyMode'
import { useBeforeUnload, useSearchParams } from 'react-router-dom'
import {
  ContentContainerStyled,
  WebMembersContainerStyled,
  WebMembersFrameContainerStyled
} from './styles'

const updateOpenTabCountOnUnload = () => {
  const presentationWindowCount = Number(
    localStorage.getItem(PRESENTATION_WINDOW_COUNT_STORAGE_KEY)
  )
  if (presentationWindowCount > 0) {
    localStorage.setItem(PRESENTATION_WINDOW_COUNT_STORAGE_KEY, String(presentationWindowCount - 1))
  }
}

const handleUnload = () => {
  updateOpenTabCountOnUnload()

  if (Number(localStorage.getItem(PRESENTATION_WINDOW_COUNT_STORAGE_KEY)) === 0) {
    localStorage.setItem(CLEAR_PRIVACY_MODE_LOCAL_STORAGE_KEY, 'true')
  }
}

const incrementPresentationWindowCount = () => {
  const currentCount = Number(localStorage.getItem(PRESENTATION_WINDOW_COUNT_STORAGE_KEY))
  localStorage.setItem(PRESENTATION_WINDOW_COUNT_STORAGE_KEY, String(currentCount + 1))
}

export const WebMembersPresentationMode = () => {
  const [searchParams] = useSearchParams()
  const userEmail = searchParams.get('userEmail')
  const coachId = searchParams.get('coachId')

  useBeforeUnload(handleUnload)

  useEffect(() => {
    localStorage.setItem(PRIVACY_MODE_LOCAL_STORAGE_KEY, 'true')
    localStorage.setItem(CLEAR_PRIVACY_MODE_LOCAL_STORAGE_KEY, 'false')
    incrementPresentationWindowCount()

    // Set up the message listener as soon as the component mounts
    const messageHandler = (event: MessageEvent) => {
      if (event.data.type === 'admin_authenticated') {
        // Find the iframe and send the message
        const iframes = document.querySelectorAll('iframe')
        iframes.forEach((iframe) => {
          if (iframe.contentWindow) {
            iframe.contentWindow.postMessage(
              {
                type: 'member_data',
                userEmail,
                coachId
              },
              '*'
            )
          }
        })
      }
    }

    window.addEventListener('message', messageHandler)

    return () => {
      updateOpenTabCountOnUnload()
      window.removeEventListener('message', messageHandler)
    }
  }, [userEmail, coachId])

  // Default desktop view with controls
  return (
    <WebMembersContainerStyled>
      <ContentContainerStyled>
        <WebMembersFrameContainerStyled>
          <iframe
            src={`${env.REACT_APP_WEB_MEMBERS_URL}/video-calls/paid?presentationMode=true`}
            title="Web Members - Desktop view"
            style={{
              border: '1px solid #e0e0e0',
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
            }}
          />
        </WebMembersFrameContainerStyled>
      </ContentContainerStyled>
    </WebMembersContainerStyled>
  )
}
