import { useCallback, useMemo } from 'react'
import { eachMonthOfInterval, endOfMonth, format, parse } from 'date-fns'
import { QueryParam } from 'queryParam.types'
import { useSearchParams } from 'react-router-dom'
import { MMMM_YYYY, YYYY_MM, YYYY_MM_DD } from 'utility/timeFormats'
import { MIN_AVAILABLE_INVOICE_PERIOD_DATE } from './constants'

function buildMonthsOptions() {
  const now = new Date()
  const start = MIN_AVAILABLE_INVOICE_PERIOD_DATE
  return eachMonthOfInterval({ start, end: now })
    .map((month) => ({ label: format(month, MMMM_YYYY), value: format(month, YYYY_MM) }))
    .reverse()
}

type BillingPeriodOption = { label: string; value: string }

const billingPeriodOptions: BillingPeriodOption[] = buildMonthsOptions()

export interface IBillingPeriodHookOptions {
  defaultCurrentPeriod?: boolean
}

export const useBillingPeriod = (options: IBillingPeriodHookOptions = {}) => {
  const { defaultCurrentPeriod = false } = options

  const [searchParams, setSearchParams] = useSearchParams()

  const periodFromParams = searchParams.get(QueryParam.CoachesBillingPeriod)

  const billingPeriod = useMemo(() => {
    if (periodFromParams) {
      const selectedOption = billingPeriodOptions.find(
        (option) => option.value === periodFromParams
      )

      if (selectedOption) {
        return selectedOption.value
      }
    }

    if (defaultCurrentPeriod) {
      return billingPeriodOptions[0].value
    }

    return billingPeriodOptions[1].value
  }, [periodFromParams, defaultCurrentPeriod])

  const setBillingPeriod = useCallback(
    (billingPeriod: string) => {
      setSearchParams((params) => ({
        ...params,
        [QueryParam.CoachesBillingPeriod]: billingPeriod
      }))
    },
    [setSearchParams]
  )

  const periodStartDate = parse(billingPeriod, YYYY_MM, new Date())
  const periodEndDate = endOfMonth(periodStartDate)

  const periodStart = format(periodStartDate, YYYY_MM_DD)
  const periodEnd = format(periodEndDate, YYYY_MM_DD)

  return {
    billingPeriodOptions,
    billingPeriod,
    setBillingPeriod,
    periodStart,
    periodEnd,
    periodStartDate,
    periodEndDate
  }
}
