export const YYYY_MM_DD = 'yyyy-MM-dd'
export const YYYY_MM = 'yyyy-MM'
export const MMMM_YYYY = 'MMMM yyyy' // April 2024
export const MMM_DD_HH_MM_A = 'LLL do, hh:mm aaa' // Apr 6th, 05:28 am
export const MMM_DD = 'LLL do' // Apr 6th
export const MMM_D = 'LLL d' // Apr 6
export const MM_DD_YYYY = 'MM/dd/yyyy'
export const MM_DD_YY = 'MM/dd/yy'
export const MMM_DD_YYYY = 'LLL do, yyyy'
export const MMMM_D_YYYY_H_MM_A = 'MMMM d, yyyy h:mm a' // April 6, 2024 05:28 am
export const TWELVE_HOURS_TIME_FORMAT = 'hh:mm aaa' // 07:28 am
export const ISO_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss'
export const ISO_DATE_FORMAT_WITH_ZONE = 'yyyy-MM-dd HH:mm:ss xx'
export const DATE_TIME_WITH_SHORT_ZONE_FORMAT = 'LLL d yyyy @ hh:mm aaa zzz'
export const LLL_D_YYYY_HH_MM_A = 'LLL d, yyyy, hh:mm a'
export const EEEE_LLL_DO = 'EEEE LLL do' // Tuesday Jun 4th
export const MMM_D_YYYY = 'MMM d, yyyy' // Aug 8, 2024
