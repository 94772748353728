import {
  Avatar,
  Box,
  BoxProps,
  DialogContent,
  Divider,
  MenuItem,
  Popper,
  Stack,
  Switch,
  Typography
} from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

const NOTE_COLOR = '#FF82251F'

export const ScrollableBox = styled(Box)({
  overflowY: 'scroll',
  overflowX: 'hidden'
})

export const ScheduledMessagesContent = styled(DialogContent)({
  minWidth: '500px'
})

export const RecentConversationsContent = styled(DialogContent)(
  (props: { 'fixed-height'?: number }) => ({
    minWidth: '500px',
    height: props['fixed-height'] ? '50vh' : 'auto'
  })
)

export const MessageAvatar = styled(Avatar)({
  width: 32,
  height: 32,
  fontSize: '1rem'
})

export const AvatarPlaceholder = styled(Box)({
  width: 42
})

export const MessageBody = styled(Box)<BoxProps & { note?: number }>(({ note }) => ({
  maxWidth: '90%',
  backgroundColor: note ? NOTE_COLOR : theme.palette['theme-primary']['level-2'],
  marginLeft: 10,
  marginRight: 10,
  borderRadius: 4,
  padding: 15,
  fontSize: 14,
  wordBreak: 'break-word',
  '& img': {
    maxWidth: '100%',
    maxHeight: '60vh'
  },
  p: {
    margin: 0
  },
  'p:empty': {
    margin: '21px'
  }
}))

export const NewMessageBox = styled(Stack)((props: { note: number }) => ({
  width: '100%',
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
  padding: 20,
  fontSize: 14,
  maxHeight: '30vh',
  'p[data-slate-node="element"]': {
    margin: 0
  },
  backgroundColor: props.note ? NOTE_COLOR : 'inherit'
}))

export const InputPlaceholder = styled(Typography)({
  cursor: 'text',
  color: theme.palette['disabled-button']['alternative-border'],
  marginBottom: 20
})

export const Toolbar = styled(Stack)({
  justifyContent: 'space-between',
  marginTop: '10px'
})

export const DividerStyled = styled(Divider)({
  margin: '7px 0 7px 5px'
})

export const MessageDivider = styled(Divider)({
  margin: '8px 0'
})

export const ActionList = styled(Stack)({
  '& .MuiButtonBase-root': {
    minWidth: 0,
    padding: '0px 4px'
  }
})

export const IconAdornmentBox = styled(Box)((props: { width?: string }) => ({
  display: 'flex',
  alignItems: 'center',
  width: props.width || 'auto',
  '& .MuiSvgIcon-root': {
    color: theme.palette.icon.secondary,
    margin: '5px 5px'
  }
}))

export const IconPlaceholder = styled(Box)({
  margin: '24px'
})

export const ActionIconsWrapper = styled(Box)({
  margin: '12px'
})

export const ActionIconsPlaceholder = styled(Box)({
  margin: '0 76px'
})

export const DateTimePickerStyled = styled(Box)({
  '& .MuiFormControl-root': {
    opacity: 0,
    width: 0,
    height: 0
  },
  '& .MuiButtonBase-root.Mui-selected': {
    backgroundColor: 'yellow'
  }
})

export const PopperStyled = styled(Popper)({
  border: '1px solid',
  borderColor: theme.palette['border-highlight']['primary-2']
})

export const InvisibleMenuItem = styled(MenuItem)({
  height: 0,
  padding: 0
})

export const NoteSwitch = styled(Switch)({
  margin: 'auto'
})
