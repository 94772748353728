import { CoachesBillingResource } from 'coachesBillingResource.types'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { LocationPaths } from 'location.types'
import { generatePath } from 'react-router-dom'
import { getBillingPeriodSearchParams } from 'screens/CoachesBilling/BillingPeriodSelect'
import {
  BillingInvoiceUserStateCell,
  getStateLabel
} from 'screens/CoachesBilling/Dashboard/InvoiceUsers/BillingInvoiceUserStateCell'
import { formatCurrency, formatDateTime, renderAsInternalLink } from 'utility/dataGrid'
import {
  CoachBillingInvoiceUserDynamicFilterField,
  CoachBillingInvoiceUserSortField,
  CoachBillingInvoiceUserBillingPeriodStates,
  ICoachBillingInvoiceUser,
  IUserMin,
  ICoachBillingInvoice,
  ICoachBillingInvoiceReview
} from 'types'

type IBillingInvoiceUsers = {
  [K in keyof ICoachBillingInvoiceUser as K]?: ICoachBillingInvoiceUser[K]
} & {
  [K in keyof IUserMin as `user.${K}`]?: IUserMin[K]
} & {
  [K in keyof ICoachBillingInvoice as `lastInvoice.${K}`]?: ICoachBillingInvoice[K]
} & {
  [K in keyof ICoachBillingInvoiceReview as `lastCoachReview.${K}`]?: ICoachBillingInvoiceReview[K]
}

export const columns: (IDataGridColumn & {
  field: keyof IBillingInvoiceUsers
})[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 50,
    type: 'number'
  },
  {
    field: 'user.email',
    headerName: 'Email',
    width: 200,
    type: 'string',
    backendFilterName: CoachBillingInvoiceUserDynamicFilterField.Email,
    backendSortName: CoachBillingInvoiceUserSortField.Email
  },
  {
    field: 'user.fullName',
    headerName: 'Name',
    width: 250,
    type: 'string',
    backendFilterName: CoachBillingInvoiceUserDynamicFilterField.FullName,
    backendSortName: CoachBillingInvoiceUserSortField.FullName,
    renderCell: renderAsInternalLink(({ id }) => {
      const searchParams = getBillingPeriodSearchParams()

      const path = generatePath(`${LocationPaths.Coaches}/${CoachesBillingResource.InvoiceItems}`, {
        coachId: id as string
      })

      return `${path}${searchParams}`
    }),
    valueGetter: ({ row }) => row.user.fullName
  },
  {
    field: 'billingPeriodState',
    headerName: 'Status',
    width: 150,
    type: 'singleSelect',
    backendFilterName: CoachBillingInvoiceUserDynamicFilterField.BillingPeriodState,
    sortable: false,
    valueOptions: Object.values(CoachBillingInvoiceUserBillingPeriodStates).map((value) => {
      return { label: getStateLabel(value), value }
    }),
    renderCell: ({ value }) => <BillingInvoiceUserStateCell state={value} />
  },
  {
    field: 'lastCoachReview.reviewedAt',
    headerName: 'Coach Reviewed At',
    width: 200,
    type: 'dateTime',
    filterable: false,
    sortable: false,
    valueFormatter: formatDateTime,
    valueGetter: ({ row }) => row.lastCoachReview?.reviewedAt
  },
  {
    field: 'lastInvoice.submittedAt',
    headerName: 'Last Invoiced',
    width: 200,
    type: 'dateTime',
    filterable: false,
    sortable: false,
    valueFormatter: formatDateTime,
    valueGetter: ({ row }) => row.lastInvoice?.submittedAt
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 150,
    type: 'number',
    filterable: false,
    backendSortName: CoachBillingInvoiceUserSortField.InvoiceItemsTotal,
    valueFormatter: formatCurrency
  }
]
