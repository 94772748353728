import { ShippingOrderProvider } from 'types'

export const AVAILABLE_PROVIDERS = [
  ShippingOrderProvider.Truepill,
  ShippingOrderProvider.Nutrisense,
  ShippingOrderProvider.Easyship,
  ShippingOrderProvider.Precision,
  ShippingOrderProvider.Avery
]

export const FORMATTED_PROVIDER: { [key in ShippingOrderProvider]: string } = {
  [ShippingOrderProvider.GiftHealth]: 'GiftHealth',
  [ShippingOrderProvider.GiftHealthManual]: 'GiftHealthManual',
  [ShippingOrderProvider.Nutrisense]: 'Nutrisense',
  [ShippingOrderProvider.Truepill]: 'Truepill',
  [ShippingOrderProvider.Precision]: 'Precision',
  [ShippingOrderProvider.Easyship]: 'Nutrisense (Easyship)',
  [ShippingOrderProvider.Avery]: 'Nutrisense (Avery)'
}
