import { format } from 'date-fns'
import { startCase } from 'lodash'
import { numericFormatter } from 'react-number-format'
import { DATE_TIME_WITH_SHORT_ZONE_FORMAT, YYYY_MM_DD } from 'utility/timeFormats'
import {
  IAccountRequest,
  ICancelSubscriptionResponsePayload,
  IChangeSubscriptionBillingCycleResponsePayload,
  ICreditResponsePayload,
  IDeleteDuplicateAccountResponsePayload,
  IMiscRequestResponsePayload,
  IOneOffAddonResponsePayload,
  IPauseSubscriptionResponsePayload,
  IRefundResponsePayload,
  IUnpauseSubscriptionResponsePayload,
  IUpdateSubscriptionResponsePayload,
  IUpsertLimitedSupportResponsePayload,
  OpsAccountRequestKind
} from 'types'

const describeUpdateSubscription = ({ nextProduct }: IUpdateSubscriptionResponsePayload) =>
  `New product: ${startCase(nextProduct)}`

const describePauseSubscription = ({
  cycleCount,
  subscriptionId
}: IPauseSubscriptionResponsePayload) =>
  `Cycle count: ${cycleCount}\nSubscription ID: ${subscriptionId}`

const describeCredit = ({ centsAmount }: ICreditResponsePayload) =>
  `Amount: $${numericFormatter(String(centsAmount / 100), {
    thousandSeparator: true,
    decimalScale: 2
  })}`

const describeOneOffAddon = ({ product, paid, discountCentsAmount }: IOneOffAddonResponsePayload) =>
  `Product: ${startCase(product)}\nPaid: ${paid}\nDiscount: $${numericFormatter(
    String((discountCentsAmount || 0) / 100),
    {
      thousandSeparator: true,
      decimalScale: 2
    }
  )}`

const describeRefund = ({ amountInCents, chargeId }: IRefundResponsePayload) =>
  `Amount: $${numericFormatter(String(amountInCents / 100), {
    thousandSeparator: true,
    decimalScale: 2
  })}\nCharge ID: ${chargeId}`

const describeCancellation = ({
  subscriptionId,
  cancellationType
}: ICancelSubscriptionResponsePayload) =>
  `Subscription ID: ${subscriptionId} \nCancellation Type: ${startCase(cancellationType)}`

const describeChangeSubscriptionBillingCycle = ({
  billingCycleAnchor,
  subscriptionId
}: IChangeSubscriptionBillingCycleResponsePayload) =>
  `Billing cycle anchor: ${format(
    new Date(billingCycleAnchor),
    DATE_TIME_WITH_SHORT_ZONE_FORMAT
  )}\nSubscription ID: ${subscriptionId}`

const describeMiscRequest = ({ requestDetails }: IMiscRequestResponsePayload) =>
  `Request details: ${requestDetails}`

const describeDeleteDuplicateAccountRequest = ({
  creditAmountInCents,
  duplicateUserId
}: IDeleteDuplicateAccountResponsePayload) =>
  `Credit: $${numericFormatter(String(creditAmountInCents / 100), {
    thousandSeparator: true,
    decimalScale: 2
  })}\nDuplicate user ID: ${duplicateUserId}`

const describeUnpauseSubscriptionRequest = ({
  resumeDate,
  subscriptionId
}: IUnpauseSubscriptionResponsePayload) => {
  const resumeAtDescription = resumeDate
    ? `Resume date: ${format(new Date(resumeDate), YYYY_MM_DD)}\n`
    : '\n'
  return `${resumeAtDescription}Subscription ID: ${subscriptionId}`
}

const describeUncancelSubscription = ({ subscriptionId }: IUnpauseSubscriptionResponsePayload) =>
  `Subscription ID: ${subscriptionId}`

const describeUpsertLimitedSupport = ({
  limitedSupportSubscriptionId,
  limitedSupportCancelAt
}: IUpsertLimitedSupportResponsePayload) =>
  `Complimentary Support ID: ${
    limitedSupportSubscriptionId || ' - '
  }\nComplimentary Support Cancel At: ${format(new Date(limitedSupportCancelAt), YYYY_MM_DD)}`

const describeGrantFreeVideoCall = () => 'Grant Free Video Call'

const KIND_TO_DESCRIBER_LOOKUP: { [key in OpsAccountRequestKind]: (payload: any) => string } = {
  [OpsAccountRequestKind.CancelSubscription]: describeCancellation,
  [OpsAccountRequestKind.ChangeSubscriptionBillingCycle]: describeChangeSubscriptionBillingCycle,
  [OpsAccountRequestKind.Credit]: describeCredit,
  [OpsAccountRequestKind.DeleteDuplicateAccount]: describeDeleteDuplicateAccountRequest,
  [OpsAccountRequestKind.GrantFreeVideoCall]: describeGrantFreeVideoCall,
  [OpsAccountRequestKind.MiscRequest]: describeMiscRequest,
  [OpsAccountRequestKind.OneOffAddon]: describeOneOffAddon,
  [OpsAccountRequestKind.PauseSubscription]: describePauseSubscription,
  [OpsAccountRequestKind.Refund]: describeRefund,
  [OpsAccountRequestKind.UncancelSubscription]: describeUncancelSubscription,
  [OpsAccountRequestKind.UnpauseSubscription]: describeUnpauseSubscriptionRequest,
  [OpsAccountRequestKind.UpdateSubscription]: describeUpdateSubscription,
  [OpsAccountRequestKind.UpsertLimitedSupport]: describeUpsertLimitedSupport
}

export const describeAccountRequestPayload = (accountRequest: IAccountRequest) =>
  // @ts-ignore
  KIND_TO_DESCRIBER_LOOKUP[accountRequest.kind]?.(accountRequest.payload) || accountRequest.kind
