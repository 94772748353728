import { lazy, Suspense } from 'react'
import {
  CoachesBillingDashboardResource,
  CoachesBillingResource
} from 'coachesBillingResource.types'
import { Loader, PrivateRoute, SnackBar } from 'components'
import { PrivacyModeWarning } from 'components/PrivacyModeWarning/PrivacyModeWarning'
import { PrivatePageLayout } from 'components/PrivatePageLayout'
import { DppResource } from 'dppResource.types'
import { usePrivacyMode } from 'hooks/usePrivacyMode'
import { KnowledgeResource } from 'knowledgeResource.types'
import { LocationPaths } from 'location.types'
import { NutritionistOrganizationResource } from 'nutritionistOrganizationResource.types'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AccountRequests } from 'screens/AccountRequests'
import { AdminPanelLayout } from 'screens/AdminPanel'
import { DashboardLayout } from 'screens/CoachesBilling/Dashboard'
import { DppLayout } from 'screens/Dpp/DppLayout'
import { MembersManagement } from 'screens/MembersManagement'
import { MobileAppPresentationModeLayout } from 'screens/MobileAppPresentationMode/MobileAppPresentationModeLayout'
import { AvailabilitySnapshots } from 'screens/NutritionistsOrganization/AvailabilitySnapshots/AvailabilitySnapshots'
import { NutritionistsGrid } from 'screens/NutritionistsOrganization/NutritionistsGrid'
import { DeleteNoteDialog } from 'screens/UserDetails/Events'
import {
  WebMembersPresentationMode,
  WebMembersPresentationModeLayout
} from 'screens/WebMembersPresentationMode'
import { ShippingResource } from 'shippingResource.types'
import { HealthPortalFeature } from 'types'
import { NotFound } from './components/NotFound'
import { OpsPageLayout } from './components/OpsPageLayout'
import { BillingDashboard } from './screens/BillingDashboard/BillingDashboard'
import { KnowledgeLayout } from './screens/Knowledge'
import { NutritionistHome } from './screens/NutritionistHome/NutritionistHome'
import { SaveEventDialog } from './screens/UserDetails/SaveEventDialog'
import { UserResource } from './userResource.types'

const UserDetails = lazy(() =>
  import('./screens/UserDetails').then((module) => ({ default: module.UserDetails }))
)
const Login = lazy(() => import('./screens/Login').then((module) => ({ default: module.Login })))

const Orders = lazy(() => import('./screens/Orders').then((module) => ({ default: module.Orders })))

const Shipments = lazy(() =>
  import('./screens/Shipments').then((module) => ({ default: module.Shipments }))
)

const InvoiceOrders = lazy(() =>
  import('./screens/InvoiceOrders').then((module) => ({ default: module.InvoiceOrders }))
)

const Files = lazy(() => import('./screens/Files').then((module) => ({ default: module.Files })))

const Chatbot = lazy(() =>
  import('./screens/Chatbot').then((module) => ({ default: module.Chatbot }))
)

const History = lazy(() =>
  import('./screens/History').then((module) => ({ default: module.History }))
)

const Questionnaire = lazy(() =>
  import('./screens/Questionnaire').then((module) => ({ default: module.Questionnaire }))
)

const AdminPanel = lazy(() =>
  import('./screens/AdminPanel').then((module) => ({ default: module.AdminPanel }))
)

const ResetPasswordRequest = lazy(() =>
  import('./screens/ResetPasswordRequest').then((module) => ({
    default: module.ResetPasswordRequest
  }))
)

const Financials = lazy(() =>
  import('./screens/Financials').then((module) => ({ default: module.Financials }))
)

const ReplacementRequests = lazy(() =>
  import('./screens/ReplacementRequests').then((module) => ({
    default: module.ReplacementRequests
  }))
)

const UserShipments = lazy(() =>
  import('./screens/UserShipments').then((module) => ({ default: module.UserShipments }))
)

const Approvals = lazy(() =>
  import('./screens/Approvals').then((module) => ({
    default: module.Approvals
  }))
)

const Surveys = lazy(() =>
  import('./screens/Surveys').then((module) => ({
    default: module.Surveys
  }))
)

const AllCompletedNotifications = lazy(() =>
  import('./screens/AdminPanel/Notifications/CompletedNotifications/CompletedNotifications').then(
    (module) => ({
      default: module.CompletedNotifications
    })
  )
)

const Insights = lazy(() =>
  import('./screens/Insights').then((module) => ({
    default: module.Insights
  }))
)

const KnowledgeQuestions = lazy(() =>
  import('./screens/Knowledge').then((module) => ({ default: module.Questions }))
)

const KnowledgeAnswers = lazy(() =>
  import('./screens/Knowledge').then((module) => ({ default: module.Answers }))
)

const KnowledgeSources = lazy(() =>
  import('./screens/Knowledge').then((module) => ({ default: module.Sources }))
)

const KnowledgeTags = lazy(() =>
  import('./screens/Knowledge').then((module) => ({ default: module.Tags }))
)

const SupportTickets = lazy(() =>
  import('./screens/SupportTickets').then((module) => ({ default: module.SupportTickets }))
)

const MobileAppPresentationMode = lazy(() =>
  import('./screens/MobileAppPresentationMode').then((module) => ({
    default: module.MobileAppPresentationMode
  }))
)

const DppEligibleUsers = lazy(() =>
  import('./screens/Dpp').then((module) => ({ default: module.EligibleUsers }))
)

const DppCohorts = lazy(() =>
  import('./screens/Dpp').then((module) => ({ default: module.Cohorts }))
)

const DppInsuranceParticipants = lazy(() =>
  import('./screens/Dpp').then((module) => ({ default: module.InsuranceParticipants }))
)

const DppInsuranceSessions = lazy(() =>
  import('./screens/Dpp').then((module) => ({ default: module.InsuranceSessions }))
)

const DppChat = lazy(() => import('./screens/Dpp').then((module) => ({ default: module.DppChat })))

const Chat = lazy(() => import('./screens/Chat').then((module) => ({ default: module.Chat })))

const NutritionistsOrganization = lazy(() =>
  import('./screens/NutritionistsOrganization').then((module) => ({
    default: module.NutritionistsOrganization
  }))
)

const CoachesBillingDashboardUsers = lazy(() =>
  import('./screens/CoachesBilling/Dashboard').then((module) => ({
    default: module.InvoiceUsers
  }))
)

const CoachesBillingDashboardTotals = lazy(() =>
  import('./screens/CoachesBilling/Dashboard').then((module) => ({
    default: module.InvoiceItemTotals
  }))
)

const CoachesBillingInvoiceDetails = lazy(() =>
  import('./screens/CoachesBilling/InvoiceDetails').then((module) => ({
    default: module.CoachesBillingInvoiceDetails
  }))
)

export const App = () => {
  usePrivacyMode()

  const userDetailsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.Home}>
      <UserDetails />
    </PrivateRoute>
  )

  const insightsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.Insights}>
      <Insights />
    </PrivateRoute>
  )

  const filesPrivate = (
    <PrivateRoute feature={HealthPortalFeature.Files}>
      <Files />
    </PrivateRoute>
  )

  const chatbotPrivate = (
    <PrivateRoute feature={HealthPortalFeature.ChatbotUserHistory}>
      <Chatbot />
    </PrivateRoute>
  )

  const historyPrivate = (
    <PrivateRoute feature={HealthPortalFeature.History}>
      <History />
    </PrivateRoute>
  )

  const questionnairePrivate = (
    <PrivateRoute feature={HealthPortalFeature.SurveysViewHealthQuestionnaire}>
      <Questionnaire />
    </PrivateRoute>
  )

  const adminViewPrivate = (
    <PrivateRoute feature={HealthPortalFeature.AdminPanelMembers}>
      <AdminPanel />
    </PrivateRoute>
  )

  const ordersPrivate = (
    <PrivateRoute feature={HealthPortalFeature.ShipmentsManagement}>
      <Orders />
    </PrivateRoute>
  )

  const shipmentsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.ShipmentsManagement}>
      <Shipments />
    </PrivateRoute>
  )

  const invoiceOrdersPrivate = (
    <PrivateRoute feature={HealthPortalFeature.ShipmentsManagement}>
      <InvoiceOrders />
    </PrivateRoute>
  )

  const financialsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.Financials}>
      <Financials />
    </PrivateRoute>
  )

  const replacementRequestsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.SensorsReplacementRequests}>
      <ReplacementRequests />
    </PrivateRoute>
  )

  const userShipmentsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.ShipmentsViewUser}>
      <UserShipments />
    </PrivateRoute>
  )

  const approvalsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.SurveysViewHealthQuestionnaireApprovals}>
      <Approvals />
    </PrivateRoute>
  )

  const surveysPrivate = (
    <PrivateRoute feature={HealthPortalFeature.SurveysManagement}>
      <Surveys />
    </PrivateRoute>
  )

  const allCompletedNotificationsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.AdminPanelMembers}>
      <AllCompletedNotifications />
    </PrivateRoute>
  )

  const chatPrivate = (
    <PrivateRoute feature={HealthPortalFeature.Chat}>
      <Chat />
    </PrivateRoute>
  )

  const knowledgeQuestionsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.ChatbotViewKnowledgeBase}>
      <KnowledgeQuestions />
    </PrivateRoute>
  )

  const knowledgeAnswersPrivate = (
    <PrivateRoute feature={HealthPortalFeature.ChatbotViewKnowledgeBase}>
      <KnowledgeAnswers />
    </PrivateRoute>
  )

  const knowledgeSourcesPrivate = (
    <PrivateRoute feature={HealthPortalFeature.ChatbotViewKnowledgeBase}>
      <KnowledgeSources />
    </PrivateRoute>
  )

  const knowledgeTagsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.ChatbotViewKnowledgeBase}>
      <KnowledgeTags />
    </PrivateRoute>
  )

  const supportTicketsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.SupportTickets}>
      <SupportTickets />
    </PrivateRoute>
  )

  const accountRequestsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.SupportAccountRequests}>
      <AccountRequests />
    </PrivateRoute>
  )

  const billingDashboardPrivate = (
    <PrivateRoute feature={HealthPortalFeature.BillingDashboard}>
      <BillingDashboard />
    </PrivateRoute>
  )

  const mobileAppPresentationMode = (
    <PrivateRoute feature={HealthPortalFeature.HomeMobileAppPresentationMode}>
      <MobileAppPresentationMode />
    </PrivateRoute>
  )

  const webMembersPresentationMode = (
    <PrivateRoute feature={HealthPortalFeature.HomeMobileAppPresentationMode}>
      <WebMembersPresentationMode />
    </PrivateRoute>
  )

  const nutritionistHomePrivate = (
    <PrivateRoute feature={HealthPortalFeature.NutritionistHome}>
      <NutritionistHome />
    </PrivateRoute>
  )

  const membersManagementPrivate = (
    <PrivateRoute feature={HealthPortalFeature.MembersManagement}>
      <MembersManagement />
    </PrivateRoute>
  )

  const dppEligibleUsersPrivate = (
    <PrivateRoute feature={HealthPortalFeature.DppAssignCohort}>
      <DppEligibleUsers />
    </PrivateRoute>
  )

  const dppCohortsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.DppViewCohorts}>
      <DppCohorts />
    </PrivateRoute>
  )

  const nutritionistsGridPrivate = (
    <PrivateRoute feature={HealthPortalFeature.EditHealthTeamStructure}>
      <NutritionistsGrid />
    </PrivateRoute>
  )

  const coachesBillingDashboardUsersPrivate = (
    <PrivateRoute feature={HealthPortalFeature.CoachesBillingDashboard}>
      <CoachesBillingDashboardUsers />
    </PrivateRoute>
  )

  const coachesBillingDashboardTotalsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.CoachesBillingDashboard}>
      <CoachesBillingDashboardTotals />
    </PrivateRoute>
  )

  const coachesBillingInvoiceDetailsPrivate = (
    <PrivateRoute feature={HealthPortalFeature.CoachesBillingDetails}>
      <CoachesBillingInvoiceDetails />
    </PrivateRoute>
  )

  const coachAvailabilityPrivate = (
    <PrivateRoute feature={HealthPortalFeature.CoachAvailability}>
      <AvailabilitySnapshots />
    </PrivateRoute>
  )

  const dppInsuranceParticipants = (
    <PrivateRoute feature={HealthPortalFeature.DppSubmitReports}>
      <DppInsuranceParticipants />
    </PrivateRoute>
  )

  const dppInsuranceSessions = (
    <PrivateRoute feature={HealthPortalFeature.DppSubmitReports}>
      <DppInsuranceSessions />
    </PrivateRoute>
  )

  const dppChatPrivate = (
    <PrivateRoute feature={HealthPortalFeature.DppViewCohorts}>
      <DppChat />
    </PrivateRoute>
  )

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Navigate to={LocationPaths.AdminPanel} replace />} />
          <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
          <Route path="/login" element={<Login />} />

          <Route path="/" element={<PrivatePageLayout />}>
            <Route path={LocationPaths.AdminPanel} element={<AdminPanelLayout />}>
              <Route path={LocationPaths.AdminPanel} element={adminViewPrivate} />
              <Route
                path={LocationPaths.AllCompletedNotifications}
                element={allCompletedNotificationsPrivate}
              />
            </Route>
            <Route path={LocationPaths.ReplacementRequests} element={replacementRequestsPrivate} />
            <Route path={LocationPaths.Approvals} element={approvalsPrivate} />
            <Route path={LocationPaths.Surveys} element={surveysPrivate} />
            <Route path={LocationPaths.Financials} element={financialsPrivate} />
            <Route path={LocationPaths.SupportTickets} element={supportTicketsPrivate} />
            <Route path={LocationPaths.AccountRequests} element={accountRequestsPrivate} />
            <Route path={LocationPaths.Knowledge} element={<KnowledgeLayout />}>
              <Route path="" element={<Navigate to={KnowledgeResource.Questions} replace />} />
              <Route path={KnowledgeResource.Questions} element={knowledgeQuestionsPrivate} />
              <Route path={KnowledgeResource.Answers} element={knowledgeAnswersPrivate} />
              <Route path={KnowledgeResource.Sources} element={knowledgeSourcesPrivate} />
              <Route path={KnowledgeResource.Tags} element={knowledgeTagsPrivate} />
            </Route>
            <Route path={LocationPaths.NutritionistHome} element={nutritionistHomePrivate} />
            <Route path={LocationPaths.MembersManagement} element={membersManagementPrivate} />
            <Route path={LocationPaths.Dpp} element={<DppLayout />}>
              <Route path="" element={<Navigate to={DppResource.Cohorts} replace />} />
              <Route path={DppResource.EligibleUsers} element={dppEligibleUsersPrivate} />
              <Route path={DppResource.Cohorts} element={dppCohortsPrivate} />
              <Route path={DppResource.CohortView} element={dppCohortsPrivate} />
              <Route path={DppResource.CohortViewSession} element={dppCohortsPrivate} />
              <Route path={DppResource.InsuranceParticipants} element={dppInsuranceParticipants} />
              <Route path={DppResource.InsuranceSessions} element={dppInsuranceSessions} />
              <Route path={DppResource.DppChat} element={dppChatPrivate} />
            </Route>
            <Route
              path={LocationPaths.NutritionistsOrganization}
              element={<NutritionistsOrganization />}
            >
              <Route
                path=""
                element={<Navigate to={NutritionistOrganizationResource.Profiles} replace />}
              />
              <Route
                path={NutritionistOrganizationResource.Profiles}
                element={nutritionistsGridPrivate}
              />
              <Route
                path={NutritionistOrganizationResource.AvailabilitySnapshots}
                element={coachAvailabilityPrivate}
              />
              <Route
                path={NutritionistOrganizationResource.AvailabilitySnapshotView}
                element={coachAvailabilityPrivate}
              />
            </Route>
            <Route path={LocationPaths.Coaches}>
              <Route path={CoachesBillingResource.BillingDashboard} element={<DashboardLayout />}>
                <Route
                  path=""
                  element={<Navigate to={CoachesBillingDashboardResource.Users} replace />}
                />
                <Route
                  path={CoachesBillingDashboardResource.Users}
                  element={coachesBillingDashboardUsersPrivate}
                />
                <Route
                  path={CoachesBillingDashboardResource.Totals}
                  element={coachesBillingDashboardTotalsPrivate}
                />
              </Route>
              <Route
                path={CoachesBillingResource.InvoiceItems}
                element={coachesBillingInvoiceDetailsPrivate}
              />
            </Route>
          </Route>

          <Route path={`${LocationPaths.Users}/:id`} element={<PrivatePageLayout />}>
            <Route path="" element={userDetailsPrivate} />
            <Route path={UserResource.History} element={historyPrivate} />
            <Route path={UserResource.Questionnaire} element={questionnairePrivate} />
            <Route path={UserResource.Files} element={filesPrivate} />
            <Route path={UserResource.Chatbot} element={chatbotPrivate} />
            <Route path={UserResource.Shipments} element={userShipmentsPrivate} />
            <Route path={UserResource.Insights} element={insightsPrivate} />
            <Route path={UserResource.Chat} element={chatPrivate} />
            <Route path={UserResource.BillingDashboard} element={billingDashboardPrivate} />
          </Route>

          <Route path="/" element={<OpsPageLayout />}>
            <Route path={ShippingResource.Orders} element={ordersPrivate} />
            <Route path={ShippingResource.Shipments} element={shipmentsPrivate} />
            <Route
              path={`${ShippingResource.Orders}/:id/${ShippingResource.Shipments}`}
              element={shipmentsPrivate}
            />
            <Route path={ShippingResource.InvoiceOrders} element={invoiceOrdersPrivate} />
            <Route path={ShippingResource.Invoices} element={invoiceOrdersPrivate} />
          </Route>

          <Route
            path={`${LocationPaths.Presentation}`}
            element={<MobileAppPresentationModeLayout />}
          >
            <Route path="" element={mobileAppPresentationMode} />
          </Route>
          <Route
            path={`${LocationPaths.WebMembersPresentation}`}
            element={<WebMembersPresentationModeLayout />}
          >
            <Route path="" element={webMembersPresentationMode} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <SnackBar />
      <SaveEventDialog />
      <DeleteNoteDialog />
      <PrivacyModeWarning />
    </>
  )
}
