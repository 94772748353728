import React, { useEffect, useState } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { ClickAwayListener, Grid, IconButton, Stack, Box, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { APP_TOOLBAR_HEIGHT, AppToolbar, CommonRightAdornment, Loader } from 'components'
import { MemberBio } from 'components/MemberBio'
import { MEMBER_BIO_HEIGHT } from 'components/MemberBio/MemberBio'
import { getLabel } from 'components/SearchUser/helpers/getLabel'
import { selectedAppointmentIdVar } from 'context/nutritionistHome/selectedAppointmentIdVar'
import { addDays, endOfDay, format, isToday, startOfDay } from 'date-fns'
import { useAuthorizedFeature } from 'hooks'
import CustomEventTypes from 'hooks/analytics/customEventTypes'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { DEFAULT_SPACING, StrongTypographyStyled } from 'styles'
import { EEEE_LLL_DO } from 'utility/timeFormats'
import {
  AppointmentDynamicFilterField,
  AppointmentSortField,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  IAppointment,
  IUser,
  SortDirection,
  useAllAppointmentsLazyQuery,
  useCurrentAdminQuery,
  useCurrentUserQuery
} from 'types'
import { HealthPortalFeature } from 'types'
import { AppointmentActions } from './AppointmentActions'
import { APPOINTMENT_DETAILS_HEIGHT, AppointmentDetails } from './AppointmentDetails'
import { CoachFilter, ICoachFilterCoach } from './CoachFilter'
import { useUserPastAppointments } from './hooks/useUserPastAppointments'
import { NutritionistCalendar } from './NutritionistCalendar'
import { ClickableBox, DatePickerStyled, GreyButton, PaddedBox, PaddedPaper } from './styles'

const DATE_PICKER_HEIGHT = 92
const gridHeight = `calc(100vh - ${APP_TOOLBAR_HEIGHT}px - ${DATE_PICKER_HEIGHT}px)`
const actionsGridHeight = `calc(${gridHeight} - ${MEMBER_BIO_HEIGHT}px - ${APPOINTMENT_DETAILS_HEIGHT}px - 16px)`

const getNutritionistDailyAppointmentsFilter = (nutritionistEmail: string, date: Date) => ({
  items: [
    {
      field: AppointmentDynamicFilterField.NutritionistEmail,
      operation: DynamicFilterItemOperation.Eq,
      value: nutritionistEmail
    },
    {
      field: AppointmentDynamicFilterField.MeetingAt,
      operation: DynamicFilterItemOperation.Gteq,
      value: startOfDay(date).toISOString()
    },
    {
      field: AppointmentDynamicFilterField.MeetingAt,
      operation: DynamicFilterItemOperation.Lteq,
      value: endOfDay(date).toISOString()
    }
  ],
  operator: DynamicFilterOperator.And
})

const appointmentsSorts = [
  {
    field: AppointmentSortField.MeetingAt,
    direction: SortDirection.Asc
  }
]

export const NutritionistHome = () => {
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const [date, setDate] = useState(new Date())
  const [getAppointments, { data, loading, refetch }] = useAllAppointmentsLazyQuery({
    notifyOnNetworkStatusChange: true
  })
  const appointments = data?.allAppointments?.appointments || []
  const { appointments: userPastAppointments } = useUserPastAppointments(currentUser?.id)
  const [datePickerOpened, setDatePickerOpened] = useState(false)
  const { track } = useSegmentInteraction()
  const incrementDate = () => setDate(addDays(date, 1))
  const decrementDate = () => setDate(addDays(date, -1))
  const [selectedUser, setSelectedUser] = useState<ICoachFilterCoach | undefined>()
  const canImpersonateNutritionist = useAuthorizedFeature(
    HealthPortalFeature.NutritionistHomeImpersonate
  )

  useEffect(() => {
    setDatePickerOpened(false)
    selectedAppointmentIdVar(null)
  }, [date])

  useEffect(() => {
    const nutritionistEmail = selectedUser?.email || currentAdmin?.email

    if (!nutritionistEmail) return

    getAppointments({
      variables: {
        dynamicFilters: getNutritionistDailyAppointmentsFilter(nutritionistEmail, date),
        sorts: appointmentsSorts
      }
    })
  }, [currentAdmin, selectedUser, date, getAppointments])

  useEffect(() => {
    if (currentAdmin) track(CustomEventTypes.NutritionistHomeOpened)
  }, [track, currentAdmin])

  return (
    <>
      <AppToolbar rightAdornment={<CommonRightAdornment />} />
      <PaddedBox>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <StrongTypographyStyled variant="h4">
            <IconButton onClick={decrementDate}>
              <KeyboardArrowLeft fontSize="large" />
            </IconButton>
            <ClickAwayListener onClickAway={() => setDatePickerOpened(false)}>
              <ClickableBox onClick={() => setDatePickerOpened(true)}>
                <DatePickerStyled>
                  <DesktopDatePicker
                    value={date}
                    onChange={(newDate) => setDate(newDate || date)}
                    open={datePickerOpened}
                  />
                </DatePickerStyled>
                {isToday(date) && 'Today, '}
                {format(date, EEEE_LLL_DO)}
              </ClickableBox>
            </ClickAwayListener>
            <IconButton onClick={incrementDate}>
              <KeyboardArrowRight fontSize="large" />
            </IconButton>
          </StrongTypographyStyled>
          <Stack direction="row" spacing={2} alignItems="center">
            {canImpersonateNutritionist && (
              <>
                <Typography variant="body2" color="text.secondary">
                  View calendar as:
                </Typography>
                <Box sx={{ width: 300 }}>
                  <CoachFilter
                    onUserSelect={(coach) => {
                      setSelectedUser(coach)
                      selectedAppointmentIdVar(null)
                    }}
                    placeholderText={getLabel(selectedUser)}
                  />
                </Box>
              </>
            )}
            <GreyButton variant="contained" size="small" onClick={() => refetch()}>
              Refresh
            </GreyButton>
          </Stack>
        </Stack>
      </PaddedBox>
      <Grid container spacing={DEFAULT_SPACING} height={gridHeight}>
        <Grid item xs={3} height="100%">
          {(!currentAdmin || loading) && (
            <PaddedPaper>
              <Loader />
            </PaddedPaper>
          )}
          {currentAdmin && !loading && (
            <NutritionistCalendar appointments={appointments as IAppointment[]} date={date} />
          )}
        </Grid>
        <Grid item xs={9} height="100%">
          <Grid container spacing={DEFAULT_SPACING} flex={1}>
            <Grid item xs={12}>
              <MemberBio showOpenChatIcon />
            </Grid>
            <Grid item xs={12}>
              <AppointmentDetails pastAppointments={userPastAppointments as IAppointment[]} />
            </Grid>
            <Grid item xs={12} height={actionsGridHeight}>
              <AppointmentActions
                currentUser={currentUser as IUser}
                coachId={selectedUser?.id || ''}
                pastAppointments={userPastAppointments as IAppointment[]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
