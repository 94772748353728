import { Tab, Tabs, Toolbar } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'
import { ShippingResource } from 'shippingResource.types'

const getTabValue = (path: string) => {
  if (path.includes(ShippingResource.Invoices) || path.includes(ShippingResource.InvoiceOrders)) {
    return 0
  }

  if (path.includes(ShippingResource.Shipments)) {
    return 2
  }

  if (path.includes(ShippingResource.Orders)) {
    return 1
  }

  return undefined
}

export const OrdersAppBar = () => {
  const location = useLocation()

  const tabValue = getTabValue(location.pathname)

  return (
    <Toolbar>
      <Tabs value={tabValue}>
        <Tab label="Invoices" component={NavLink} to={ShippingResource.Invoices} />
        <Tab label="Orders" component={NavLink} to={ShippingResource.Orders} />
        <Tab label="Shipments" component={NavLink} to={ShippingResource.Shipments} />
      </Tabs>
    </Toolbar>
  )
}
