const enum CustomEventTypes {
  ChatMessageSent = 'Chat Message Sent',
  NutritionistSession = 'Nutritionist Session',
  PrimaryGoalChanged = 'Primary Goal Changed',
  PrimaryGoalMetricsChanged = 'Primary Goal Metrics Changed',
  GoalMetricTargetChanged = 'Goal Metric Target Changed',
  MemberNoteAdded = 'Member Note Added',
  MemberNotePinned = 'Member Note Pinned',
  StartPresentationMode = 'Start Presentation Mode',
  StartWebMembersPresentationMode = 'Start Web Members Presentation Mode',
  NutritionistHomeOpened = 'Nutritionist Home Opened',
  NutritionistHomeCalendarEventClicked = 'Nutritionist Home Calendar Event Clicked',
  NutritionistHomeSmsReminderSent = 'Nutritionist Home SMS Reminder Sent',
  EditDailyActionsModalAddActionClicked = 'Edit Daily Actions Modal - Add Action',
  EditDailyActionsModalRemoveActionClicked = 'Edit Daily Actions Modal - Remove Action'
}

export default CustomEventTypes
