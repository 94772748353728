import { theme } from 'theme'
import { InsuranceBerryStreetAppointmentMeetingStatus } from 'types'
import { IEventStatus } from './types'

export const EventStatuses: {
  [key in InsuranceBerryStreetAppointmentMeetingStatus]: IEventStatus
} = {
  occurred: {
    value: 'Occurred',
    backgroundColor: theme.palette['brand-primary']['main'],
    borderColor: theme.palette['brand-primary']['main'],
    active: true
  },
  no_show: {
    value: 'No Show',
    backgroundColor: theme.palette['theme-primary']['level-1'],
    borderColor: theme.palette.danger.main,
    active: false
  },
  rescheduled: {
    value: 'Rescheduled',
    backgroundColor: theme.palette['theme-primary']['level-1'],
    borderColor: theme.palette.danger.main,
    active: false
  },
  cancelled: {
    value: 'Cancelled',
    backgroundColor: theme.palette['theme-primary']['level-1'],
    borderColor: theme.palette.danger.main,
    active: false
  },
  pending_reschedule: {
    value: 'Pending Reschedule',
    backgroundColor: theme.palette.warning.light,
    borderColor: theme.palette.warning.light,
    active: true
  },
  late_cancellation: {
    value: 'Late Cancellation',
    backgroundColor: theme.palette['theme-primary']['level-1'],
    borderColor: theme.palette.danger.main,
    active: false
  }
}

export const CustomEventStatuses: { [key: string]: IEventStatus } = {
  pendingConfirmed: {
    value: 'Pending Confirmed',
    backgroundColor: theme.palette.warning.light,
    borderColor: theme.palette.warning.light,
    active: true
  },
  confirmed: {
    value: 'Confirmed',
    backgroundColor: theme.palette['brand-primary']['main'],
    borderColor: theme.palette['brand-primary']['main'],
    active: true
  }
}
