import { GridFilterItem } from '@mui/x-data-grid'
import { OPERATOR_TO_PREDICATE_MAP } from 'components/DataGrid/constants/filterPredicates'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { add, startOfDay, format } from 'date-fns'
import { snakeCase } from 'lodash'
import { parseUtcDate, parseUtcDateTime } from 'utility/dateParsers'
import { YYYY_MM_DD } from 'utility/timeFormats'
import { DynamicFilterItemOperation } from 'types'

export const getFiltersFromFilterItem = (
  filterItem: GridFilterItem,
  columns: IDataGridColumn[]
) => {
  const { operatorValue, value: rawValue, columnField } = filterItem

  if (!operatorValue) {
    return null
  }

  let value = rawValue

  const columnDefinition = columns.find((column) => column.field === columnField)

  if (columnDefinition?.type === 'number') {
    // we should convert string to number as value is persisted on column change
    // we do not want to filter number column by string value
    value = columnDefinition.valueParser?.(rawValue) || Number(rawValue)

    if (typeof value === 'number') {
      value = isNaN(value) || rawValue === '' ? undefined : value.toString()
    }
  }

  const valueIsMissing = Array.isArray(value) ? value.length === 0 : !value

  if (valueIsMissing && operatorValue !== 'isEmpty' && operatorValue !== 'isNotEmpty') {
    return null
  }

  const columnInSnakeCase = snakeCase(columnDefinition?.backendFilterName || columnField)

  if (columnDefinition?.type === 'dateTime') {
    // if value is in datetime format
    if (value && value.includes('T')) {
      const filterValue = parseUtcDateTime(value) as Date

      switch (operatorValue) {
        case 'is':
          return [
            {
              field: columnInSnakeCase,
              operation: OPERATOR_TO_PREDICATE_MAP['='],
              value: filterValue.toISOString()
            }
          ]
        case 'after':
          return [
            {
              field: columnInSnakeCase,
              operation: OPERATOR_TO_PREDICATE_MAP['>'],
              value: filterValue.toISOString()
            }
          ]
        case 'onOrAfter':
          return [
            {
              field: columnInSnakeCase,
              operation: OPERATOR_TO_PREDICATE_MAP['>='],
              value: filterValue.toISOString()
            }
          ]
        case 'before':
          return [
            {
              field: columnInSnakeCase,
              operation: OPERATOR_TO_PREDICATE_MAP['<'],
              value: filterValue.toISOString()
            }
          ]
        case 'onOrBefore':
          return [
            {
              field: columnInSnakeCase,
              operation: OPERATOR_TO_PREDICATE_MAP['<='],
              value: filterValue.toISOString()
            }
          ]
      }
    }

    if (operatorValue === 'isNotEmpty') {
      return [
        {
          field: columnInSnakeCase,
          operation: DynamicFilterItemOperation.NotNull,
          value: 'true'
        }
      ]
    }
  }

  if (['dateTime', 'date'].includes(columnDefinition?.type || '')) {
    if (value) {
      const filterValue = parseUtcDate(value) as Date

      let date = startOfDay(filterValue)
      if (operatorValue === 'is') {
        const from = date
        const to = add(from, { days: 1 })
        return [
          {
            field: columnInSnakeCase,
            operation: OPERATOR_TO_PREDICATE_MAP['>='],
            value: format(from, YYYY_MM_DD)
          },
          {
            field: columnInSnakeCase,
            operation: OPERATOR_TO_PREDICATE_MAP['<'],
            value: format(to, YYYY_MM_DD)
          }
        ]
      } else if (operatorValue === 'after') {
        date = add(date, { days: 1 })
        return [
          {
            field: columnInSnakeCase,
            operation: OPERATOR_TO_PREDICATE_MAP['>='],
            value: format(date, YYYY_MM_DD)
          }
        ]
      } else if (operatorValue === 'onOrAfter') {
        return [
          {
            field: columnInSnakeCase,
            operation: OPERATOR_TO_PREDICATE_MAP['>='],
            value: format(date, YYYY_MM_DD)
          }
        ]
      } else if (operatorValue === 'before') {
        return [
          {
            field: columnInSnakeCase,
            operation: OPERATOR_TO_PREDICATE_MAP['<'],
            value: format(date, YYYY_MM_DD)
          }
        ]
      } else if (operatorValue === 'onOrBefore') {
        date = add(date, { days: 1 })
        return [
          {
            field: columnInSnakeCase,
            operation: OPERATOR_TO_PREDICATE_MAP['<'],
            value: format(date, YYYY_MM_DD)
          }
        ]
      }
    } else {
      if (operatorValue === 'isNotEmpty') {
        return [
          {
            field: columnInSnakeCase,
            operation: DynamicFilterItemOperation.NotNull,
            value: 'true'
          }
        ]
      }
    }
  }

  if ((columnDefinition && !columnDefinition.type) || columnDefinition?.type === 'string') {
    if (operatorValue === 'isEmpty') {
      return [
        {
          field: columnInSnakeCase,
          operation: DynamicFilterItemOperation.Blank,
          value: 'true'
        }
      ]
    }

    if (operatorValue === 'isNotEmpty') {
      return [
        {
          field: columnInSnakeCase,
          operation: DynamicFilterItemOperation.Present,
          value: 'true'
        }
      ]
    }
  }

  const filterValue: string = Array.isArray(value) ? value.join(',') : value ?? 'true'
  return [
    {
      field: columnInSnakeCase,
      operation: OPERATOR_TO_PREDICATE_MAP[operatorValue as keyof typeof OPERATOR_TO_PREDICATE_MAP],
      value: filterValue
    }
  ]
}
