import { startCase } from 'lodash'
import { CoachBillingInvoiceUserBillingPeriodStates } from 'types'
import { BillingInvoiceUserStateText } from './styles'

interface IBillingInvoiceUserStateCellProps {
  state: CoachBillingInvoiceUserBillingPeriodStates
}

export const getStateLabel = (state: CoachBillingInvoiceUserBillingPeriodStates) => {
  switch (state) {
    case CoachBillingInvoiceUserBillingPeriodStates.Inactive:
      return 'No Activity'
    case CoachBillingInvoiceUserBillingPeriodStates.Pending:
      return 'Pending Coach'
    case CoachBillingInvoiceUserBillingPeriodStates.CoachReviewed:
      return 'Pending Invoice'
    default:
      return startCase(state)
  }
}

export const BillingInvoiceUserStateCell = ({ state }: IBillingInvoiceUserStateCellProps) => {
  return (
    <BillingInvoiceUserStateText state={state}>{getStateLabel(state)}</BillingInvoiceUserStateText>
  )
}
