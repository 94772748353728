import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Chip, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { Loader } from 'components'
import { useConfirm } from 'material-ui-confirm'
import { theme } from 'theme'

export interface IBulkUpdateNutritionists {
  bulkSaveChanges: () => void
  discardChanges: () => void
  savingChanges: boolean
}

export const BulkUpdateNutritionists = ({
  bulkSaveChanges,
  discardChanges,
  savingChanges
}: IBulkUpdateNutritionists) => {
  const confirm = useConfirm()

  const bulkUpdateHandler = async () => {
    const { confirmed } = await confirm({
      description: 'Save changes?'
    })
    if (!confirmed) {
      return
    }
    await bulkSaveChanges()
  }

  return (
    <>
      <Chip
        sx={{ marginLeft: 10, marginRight: 1, color: theme.palette['danger'].main }}
        label="Unsaved Changes!"
      />
      {!savingChanges && (
        <>
          <IconButton size="small" onClick={bulkUpdateHandler} disabled={savingChanges}>
            <CheckIcon sx={{ color: theme.palette['brand-primary'].main }} />
          </IconButton>
          <IconButton size="small" onClick={discardChanges}>
            <CloseIcon sx={{ color: theme.palette['danger'].main }} />
          </IconButton>
        </>
      )}
      {savingChanges && (
        <Box>
          <Loader />
        </Box>
      )}
    </>
  )
}
